import axios from 'axios';
import * as Lib from 'utils/Lib';


export const getOrderDetail = async (orderUid, callback) => {

  try{
    const response = await axios.get(Lib.requestURL('/api/order/' + orderUid), Lib.requestHeader());
    const responseBody = await response.data;

    callback(response.status, responseBody);
  }
  catch(error){
    Lib.requestErrorHandler(error, callback);
  }

}

export const claimOrder = async (orderUid, customerName, customerEmail, customerPhone, regionId, addressDetail, 
  shippingDuration, courier, coordinate, useInsurance, callback) => {

  try{

    const body = {
      customerName,
      customerEmail,
      customerPhone,
      regionId,
      addressDetail,
      shippingDuration, 
      courier, 
      coordinate,
      useInsurance
    }

    const response = await axios.put(Lib.requestURL('/api/order/' + orderUid), body, Lib.requestHeader());
    const responseBody = await response.data;

    callback(response.status, responseBody);

  }
  catch(error){
    Lib.requestErrorHandler(error, callback);
  }

}


export const completedOrder = async (orderUid, callback) => {

  try{

    const response = await axios.put(Lib.requestURL(`/api/c/order/${orderUid}/completed`), null, Lib.requestHeader());
    const responseBody = await response.data;

    callback(response.status, responseBody);

  }
  catch(error){
    Lib.requestErrorHandler(error, callback);
  }

}

export const getRegionList = async (keyword, callback) => {

  try{

    const queries = Lib.createParameter({
      keyword: keyword
    });

    const response = await axios.get(Lib.requestURL(`/api/order/shipping/region?&${queries}`), Lib.requestHeader());
    const responseBody = await response.data;

    callback(response.status, responseBody);

  }
  catch(error){
    Lib.requestErrorHandler(error, callback);
  }
}

export const getAvailableTariff = async (orderUid, destination, coordinate, callback) => {

  try{

    const queryParams = {
      orderUid: orderUid,
      destination: destination
    };

    if(coordinate !== null){
      queryParams.lat = coordinate.lat;
      queryParams.lng = coordinate.lng;
    }

    const queries = Lib.createParameter(queryParams);

    const response = await axios.get(Lib.requestURL(`/api/order/shipping/tariff?&${queries}`), Lib.requestHeader());
    const responseBody = await response.data;

    callback(response.status, responseBody);

  }
  catch(error){
    Lib.requestErrorHandler(error, callback);
  }


}


export const getTracking = async (orderUid, courier, waybill, callback) => {

  try{

    const queries = Lib.createParameter({
      courier: courier,
      waybill: waybill
    });

    const response = await axios.get(Lib.requestURL(`/api/c/order/${orderUid}/shipping/tracking?${queries}`), Lib.requestHeader());
    const responseBody = await response.data;

    callback(response.status, responseBody);

  }
  catch(error){
    Lib.requestErrorHandler(error, callback);
  }


}