

function Landing() {


  return(
    <div>
      <h1>Order id not found</h1>
    </div>
  );

}
export default Landing;