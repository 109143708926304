import { useState, useRef, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import HttpStatus from 'http-status-codes';
import ReactCodeInput from "react-code-input";
import Button from 'components/Button';
import { requiredVerify, requestOTP, verifyOTP } from 'service/AuthService';
import * as Lib from 'utils/Lib';


function Verify() {

  const { orderUid } = useParams();

  const [claimedPhone, setClaimedPhone] = useState('xx');
  const [verifying, setVerifying] = useState(false);
  const [message, setMessage] = useState('');
  const [code, setCode] = useState('');
  const [countdown, setCountdown] = useState(0);

  const ref = useRef(null);
  const navigate = useNavigate();


  const startCountdown = () => {
    ref.current = setInterval(() => {

      setCountdown((count) => count - 1);

      return () => clearInterval(ref.current);
    }, 1000);
  }

  const stopCountdown = () => {
    clearInterval(ref.current);
  }

  const handleRequestOTP = () => {

    requestOTP(claimedPhone, 
      (status, body) => {
        if(status === HttpStatus.OK){
          setVerifying(true);
        }
        else{
          setVerifying(false);
        }
      });
  }

  const handleSubmit = () => {

    verifyOTP(orderUid, claimedPhone, code, 
      (status, body) => {
        if(status === HttpStatus.OK){
          stopCountdown();
          navigate(`/${orderUid}`, {replace: true});
        }
        else{
          setMessage('Invalid token');
        }
      });

  }


  useEffect(() => {

    requiredVerify(orderUid, 
      (status, body) => {

        if(status === HttpStatus.OK){
          setClaimedPhone(body.data.claimedPhone);
        }
      });

  }, []);



  useEffect(() => {
    if(countdown === 0){
      stopCountdown();
    }

  }, [countdown]);



  useEffect(() => {

    if (verifying) {
      setCountdown(59);
      startCountdown();
    }
    else {
      stopCountdown();
    }

  }, [verifying]);





  const renderResendButton = () => {
    if (countdown > 0) {
      return (
        <div className="text-gray-400 text-sm">
          Wait until &nbsp;<span className="font-bold">{`${countdown} seconds`}</span>&nbsp; to resend.
        </div>
      );
    }

    return (
      <div className="flex flex-row text-sm text-gray-400">
        Didn't get the code?&nbsp;<div className="text-primary font-bold cursor-pointer" role="button"
          onClick={() => {
            handleRequestOTP();
            setCountdown(59);
            startCountdown();
          }}>Send again</div>
      </div>
    );
  }


  const inputProps = {
    inputStyle: {
      margin: '0 10px 0 0',
      MozAppearance: 'textfield',
      fontSize: '18px',
      fontWeight: '700',
      width: '45px',
      height: '50px',
      paddingLeft: '15px',
      backgroundColor: 'white',
      color: '#0E1823',
      border: '2px solid #0E1823',
      borderRadius: '10px',
    },
    inputStyleInvalid: {
      margin: '0 10px 0 0',
      MozAppearance: 'textfield',
      fontSize: '18px',
      fontWeight: '700',
      width: '45px',
      height: '50px',
      paddingLeft: '15px',
      backgroundColor: 'white',
      color: '#0E1823',
      border: '2px solid #0E1823',
      borderRadius: '10px',
    }
  }


  return (
    <div className="w-full">
      <div className="bg-white max-w-lg px-6 py-8 mx-auto mt-20 rounded-xl">
        <div className="flex flex-row items-center mb-5">
          <i className="fi fi-br-shield-check text-4xl text-secondary"></i>
          <div className="ml-2 font-bold text-lg">{`#${orderUid}`}</div>
        </div>
        <div className=" mb-10">
          {`Silakan verifikasi kembali melalui kode yang akan dikirimkan via Whatsapp ke nomor ${Lib.blurPhone(claimedPhone)}.`}
        </div>
        {
          verifying ?
            <div>
              <div className="font-bold text-red-400 my-5">{message}</div>

              <div className="mt-5 mb-10">
                <ReactCodeInput type="number" fields={4} {...inputProps}
                  value={code} onChange={(v) => { setCode(v) }} />
              </div>

              <div className="flex flex-row items-center">
                <Button label="Submit" className="bg-primary text-white h-12 w-32 mr-4"
                  onClick={() => {
                    handleSubmit();
                  }} />
                {renderResendButton()}
              </div>
            </div> :
            <div>
              <Button label="Kirim kode verifikasi" className="bg-primary text-white h-12"
                onClick={handleRequestOTP}/>
            </div>
        }
      </div>
    </div>
  );

}
export default Verify;