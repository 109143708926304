

function IconButton({
  icon,
  className,
  onClick = () => {}
}) {

  return (
    <div className={`center hover:bg-gray-200 rounded-xl p-2 cursor-pointer ${className}`} 
      onClick={() => onClick()}>
      {icon}
    </div>
  );
}
export default IconButton;