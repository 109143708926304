import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import 'utils/uicons/css/uicons-bold-rounded.css';
import './App.css';
import Landing from 'pages/Landing';
import Complain from 'pages/Complain';
import Pay from 'pages/Pay';
import Verify from 'pages/Verify';
import Refund from 'pages/Refund';
import Notification from 'pages/Notification';
import NotFound from 'pages/NotFound';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/:orderUid/notification" element={<Notification/>}/>
        <Route path="/:orderUid/refund" element={<Refund/>}/>
        <Route path="/:orderUid/complain" element={<Complain/>}/>
        <Route path="/:orderUid/verify" element={<Verify/>}/>
        <Route path="/:orderUid" element={<Pay/>}/>
        <Route path="/" element={<Landing/>}/>
        <Route path="404" element={<NotFound/>} />
        <Route path="*" element={<Navigate to="/404" replace/>}/>
      </Routes>
    </Router>
  );
}
export default App;