import {useState} from 'react';
import CouponForm from './CouponForm';

function CouponButton() {

  const [couponApplied, setCouponApplied] = useState(false);
  const [couponFormVisible, setCouponFormVisible] = useState(false);



  return(
    <>
      <CouponForm visible={couponFormVisible} onClose={() => setCouponFormVisible(false)}/>
      {
        couponApplied ?
        <div>Coupon applied. <span className="font-bold text-red-400">Remove</span> </div>
        :
        <div className="font-bold text-primary cursor-pointer" role="button"
          onClick={() => setCouponFormVisible(true)}>Ada voucher?</div>
      }
    </>
  );
}
export default CouponButton;