

function TextButton({
    label = '',
    className = 'text-primary',
    onClick = () => {}
}) {

    return(
        <div className={`font-bold cursor-pointer hover:text-opacity-90 ${className}`}
            onClick={() => onClick()}>{label}</div>
    );

}
export default TextButton;