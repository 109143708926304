import { useState, useRef, useEffect } from "react";
import IconButton from "./IconButton";
import TextField from "./TextField";
import Button from "./Button";


function CouponForm({
  visible = false,
  onReddem = () => {},
  onClose = () => {}
}){

  const handleClose = () => {
    onClose();
  }



  return(
    visible &&
    <div className="fixed flex flex-col items-center top-0 bottom-0 left-0 right-0 z-10 bg-black bg-opacity-75"
      onClick={handleClose}>

      <div className="bg-white w-full max-w-lg px-6 py-8 rounded-xl my-10"
        onClick={(e) => e.stopPropagation()}>

        <div className="flex flex-row items-center mb-5">
          <i className="fi fi-br-ticket text-3xl text-secondary mr-2"></i>
          <div className="font-bold text-lg flex-1">Have a coupon code?</div>
          <IconButton icon={<i className="fi fi-br-cross-small text-2xl"></i>}
            onClick={handleClose} />
        </div>

        <p className="text-sm text-gray-400 mb-5">Enter your coupon code below to redeem it.</p>

        <TextField className="mb-5" />

        <div className="flex justify-end">
          <Button label="Redeem" className="bg-primary text-white h-12 w-32"
            onClick={() => { }} />
        </div>

      </div>

    </div>
  );
}
export default CouponForm;