import { useState, useRef } from "react";
import IconButton from "./IconButton";
import Maps from "./Maps";
import Button from "./Button";


function MapsForm({
  givenPosition = null,
  visible = false,
  onSave = (pinpoint) => { },
  onClose = () => { }
}) {

  const unconfirmLatLng = useRef(null);




  const handleClose = () => {
    unconfirmLatLng.current = null;
    onClose();
  }

  return (
    visible &&
    <div className="fixed flex flex-col items-center top-0 bottom-0 left-0 right-0 z-10 bg-black bg-opacity-75">
      <div className="bg-white w-full max-w-xl px-5 py-7 my-10 sm:my-24 rounded-xl">

        <div className="flex flex-row items-center mb-2 w-full">
          <i className="fi fi-br-map-marker text-secondary text-2xl mr-5"></i>
          <div className="font-bold text-lg flex-1">Pilih Pinpoint</div>
          <IconButton icon={<i className="fi fi-br-cross-small text-secondary text-2xl"></i>} onClick={handleClose} />
        </div>

        <div className="mb-10">
          <Maps givenPosition={givenPosition} 
            onSelectedPosition={
              (pinpoint) => {
                unconfirmLatLng.current = pinpoint;
              }} />
        </div>

        <Button className="w-full bg-primary text-white h-12" label="Pilih Lokasi"
            onClick={() => {
              onSave(unconfirmLatLng.current);
            }} />

      </div>
    </div>
  );
}
export default MapsForm;