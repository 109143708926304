import {useState,  useEffect, forwardRef, useImperativeHandle } from 'react';
import IconButton from './IconButton';
import * as Lib from 'utils/Lib';


function ProductPreview({}, ref){

  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [productName, setProductName] = useState('');
  const [productPrice, setProductPrice] = useState(0);
  const [weight, setWeight] = useState(null);
  const [description, setDescription] = useState('');
  const [visible, setVisible] = useState(false);


  useEffect(() => {

    if(visible){
      // Disabled background Scrolling while popup is open
      if (typeof window != 'undefined' && window.document) {
        document.body.style.overflow = 'hidden';
      }
    }
    else{
      document.body.style.overflow = 'unset';
    }

  }, [visible]);



  useImperativeHandle(ref, () => ({
    show: (images, productName, productPrice, weight, description) => {

      setImages(images);
      setProductName(productName);
      setProductPrice(productPrice);
      setWeight(weight);
      setDescription(description);

      setVisible(true);
    }
  }));

  const handleClose = () => {
    setSelectedImageIndex(0);
    setImages([]);
    setProductName('');
    setProductPrice(0);
    setWeight(null);
    setDescription('');
    setVisible(false);
  }

  const left = () => {

    if(selectedImageIndex === 0){
      setSelectedImageIndex(images.length - 1);
    }
    else{
      setSelectedImageIndex(prevIndex => prevIndex - 1);
    }

  }

  const right = () => {
    if(selectedImageIndex === (images.length - 1)){
      setSelectedImageIndex(0);
    }
    else{
      setSelectedImageIndex(prevIndex => prevIndex + 1);
    }
  }




  return(
    visible &&
    <div className="fixed flex flex-col items-center top-0 bottom-0 left-0 right-0 z-10 bg-black bg-opacity-75" 
      onClick={handleClose}>

        <div className="bg-white w-full max-w-md px-6 py-8 my-10 sm:my-24 rounded-xl overflow-y-auto" 
          onClick={(e) => e.stopPropagation()}>

          <div className="flex flex-row justify-end items-center mb-5">
            <IconButton icon={<i className="fi fi-br-cross-small text-2xl"></i>} onClick={handleClose}/>
          </div>

          {
            images.length > 0 ?
            <div className="relative mb-5">
              <div className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white shadow-lg w-10 h-10 rounded-full center cursor-pointer"
                onClick={left}>
                <i className="fi fi-br-angle-small-left text-xl leading-none"></i>
              </div>
              <img className="w-full" src={Lib.imageURL(images[selectedImageIndex])} alt="product image"/>
              <div className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white shadow-lg w-10 h-10 rounded-full center cursor-pointer"
                onClick={right}>
                <i className="fi fi-br-angle-small-right text-xl leading-none"></i>
              </div>
              <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 bg-white shadow-lg px-3 py-1 rounded-xl text-sm">
                {`${selectedImageIndex + 1}/${images.length}`}
              </div>
            </div> :
            <div className="w-full h-80 bg-green-100 center mb-5">
              <i className="fi fi-br-box-alt text-4xl"></i>
            </div>
          }

          

          <div className="text-lg">{productName}</div>
          <div className="font-bold">{Lib.formatCurrency(productPrice, true)}</div>
          <div className=' mb-5'>{Lib.formatWeight(weight)}</div>
          <div className="text-sm text-gray-400">{description}</div>

        </div>
    </div>
  );



}
export default forwardRef(ProductPreview);

