import BeatLoader from 'react-spinners/BeatLoader';

function LoadingButton({
  label = 'ACTION',
  loading = false,
  loadingLabel = 'LOADING',
  className = 'bg-secondary text-white w-full h-12',
  onClick = () => { }
}) {

  return (
    <div className={`center font-bold ${loading ? 'cursor-wait' : 'cursor-pointer'} rounded-xl shadow-sm hover:bg-opacity-90 ${className}`}
      onClick={() => {
        
        if(!loading){
          onClick();
        }

      }}>
      {
        loading ?
        <div className="flex flex-row items-center">
          <div className="mr-2">{loadingLabel}</div>
          <BeatLoader loading={loading} speedMultiplier={0.75} color="white" size={6}/> 
        </div> :
        <div>{label}</div>
      }
    </div>
  );
}
export default LoadingButton;