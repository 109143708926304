import { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import HttpStatus from 'http-status-codes';
import SubPage from "components/SubPage";
import SearchableListBox from 'components/SearchableListBox';
import BankAccountField from 'components/BankAccountField';
import LoadingButton from 'components/LoadingButton';
import TextButton from 'components/TextButton';
import Toast from 'components/Toast';
import iconRefund from 'images/icon-refund.png';
import { getRefundProcess, getBankList, validateAccount, saveCustomerBank, requestRefund } from 'service/RefundService';
import * as Lib from 'utils/Lib';


function Refund() {

  const { orderUid } = useParams();

  const [shouldRefund, setShouldRefund] = useState(false);
  const [refundProcessId, setRefundProcessId] = useState(null);
  const [refundAmount, setRefundAmount] = useState(null);
  const [refundFee, setRefundFee] = useState(null);
  const [bankCode, setBankCode] = useState(null);
  const [bankName, setBankName] = useState(null);
  const [bankAccount, setBankAccount] = useState(null);
  const [accountName, setAccountName] = useState(null);
  const [approvalNote, setApprovalNote] = useState(null);
  const [refundStatus, setRefundStatus] = useState(null);
  const [refundedDate, setRefundedDate] = useState(null);
  const [disburseId, setDisburseId] = useState(null);
  const [referenceNumber, setReferenceNumber] = useState(null);

  const [ktpImage, setKtpImage] = useState(null);
  const [selectedBankIndex, setSelectedBankIndex] = useState(-1);
  const [bankList, setBankList] = useState([]);
  const [inputBankCode, setInputBankCode] = useState(null);
  const [inputBankAccount, setInputBankAccount] = useState(null);
  const [resultAccountName, setResultAccountName] = useState(null);
  const [enableSubmitBank, setEnableSubmitBank] = useState(false);

  const [pageLoading, setPageLoading] = useState(true);
  const [checkBankAccountLoading, setCheckBankAccountLoading] = useState(false);
  const [submitBankLoading, setSubmitBankLoading] = useState(false);


  const inputKtpRef = useRef();
  const toast = useRef();

 
  
  useEffect(() => {

    fetchRefundProcess();
    fetchBankList();

  }, []);

  useEffect(() => {
    checkEnableSubmitBank();
  }, [ktpImage, resultAccountName]);


  const fetchBankList = () => {
    getBankList(
      (status, body) => {

        if (status === HttpStatus.OK) {
          setBankList(toListItem(body.data));
        }

      }
    );
  }



  const fetchRefundProcess = () => {
    getRefundProcess(orderUid,
      (status, body) => {

        if (status == HttpStatus.OK) {

          const refundData = body.data;

          setShouldRefund(refundData.shouldRefund);
          setRefundProcessId(refundData.refundProcessId);
          setRefundAmount(refundData.refundAmount);
          setRefundFee(refundData.refundFee);
          setBankCode(refundData.bankCode);
          setBankName(refundData.bankName);
          setBankAccount(refundData.bankAccount);
          setAccountName(refundData.accountName);
          setApprovalNote(refundData.accountApprovalNote);
          setRefundStatus(refundData.refundStatus);
          setRefundedDate(refundData.refundedDate);
          setDisburseId(refundData.disburseId);
          setReferenceNumber(refundData.referenceNumber);
        }


      });
  }

  const handleSubmitBank = () => {

    const customerBank = {  
      'bankCode': inputBankCode,
      'bankAccount': inputBankAccount,
      'accountName': resultAccountName,
      'ktpImage': ktpImage
    }

    setSubmitBankLoading(true);

    saveCustomerBank(orderUid, customerBank,
      (status, body) => {

        setSubmitBankLoading(false);

        if (status === HttpStatus.OK) {
          toast.current.show('Rekening berhasil disubmit. Mohon tunggu proses review.');
          fetchRefundProcess();
        }
      });

  }


  const toListItem = (list) => {

    const listItem = [];

    for (let i = 0; i < list.length; i++) {
      listItem.push({
        label: list[i].bankName,
        value: list[i].bankCode
      });
    }

    return listItem;
  }


  const handleRequestRefund = () => {

    requestRefund(orderUid, refundProcessId, 
      (status, body) => {
        if(status == HttpStatus.OK){
          toast.current.show('Permintaan penarikan dana anda telah kami terima');
          fetchRefundProcess();
        }
      });
  }


  const handleSelectIdFile = (e) => {

    const file = e.target.files[0];

    if (file.size < 2000000) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = async (e) => {

        setKtpImage(reader.result);

      };
    }
    else {
      toast.current.show('Ukuran file melebihi 2MB');
    }

  }

  const handleValidateAccount = () => {

    if (inputBankCode === null) {
      toast.current.show('Mohon pilih bank');
      return;
    }
    if (inputBankAccount === null) {
      toast.current.show('Mohon masukkan nomor rekening');
      return;
    }

    setCheckBankAccountLoading(true);

    validateAccount(inputBankCode, inputBankAccount,
      (status, body) => {

        setCheckBankAccountLoading(false);

        if (status === HttpStatus.OK) {
          setResultAccountName(body.data.accountName);
        }
        else {
          setResultAccountName(null);
          toast.current.show('Rekening tidak valid');
        }

      });

  }

  const checkEnableSubmitBank = () => {

    setEnableSubmitBank(false);
    if (ktpImage == null) {
      return;
    }
    if (inputBankCode === null) {
      return;
    }
    if (inputBankAccount === null) {
      return;
    }
    if (resultAccountName === null) {
      return;
    }
    setEnableSubmitBank(true);

  }




  const renderRefundAmount = () => (
    <div className="flex flex-row items-center rounded-xl p-5 bg-green-100">
      <img className="w-10 mr-2" src={iconRefund} alt="refund" />
      <div className="text-2xl font-bold">{Lib.formatCurrency(refundAmount, true)}</div>
    </div>
  );

  const renderAccountBankForm = () => (
    <div>
      <div className="font-bold">Upload foto KTP</div>
      <div className="text-sm text-gray-400 mb-5">JPG, or PNG. Max size of 2 MB</div>
      <div className="mb-5">
        {
          ktpImage === null ?
            <div className="w-60 h-32 border-dashed border-4 border-gray-200 center rounded-2xl cursor-pointer"
              onClick={() => inputKtpRef.current.click()}>
              <i className="fi fi-br-id-badge text-2xl text-gray-400 "></i>
              <input className="hidden" type="file" accept=".jpg,.jpeg,.png" ref={inputKtpRef} onChange={handleSelectIdFile} />
            </div> :
            <div className="relative w-60 h-32 rounded-xl overflow-hidden cursor-pointer group"
              onClick={() => setKtpImage(null)}>
              <img className="w-full h-full object-cover" src={ktpImage} />
              <div className="absolute left-0 right-0 top-0 bottom-0 bg-black bg-opacity-70 hidden group-hover:flex center">
                <i className="fi fi-br-trash text-white text-2xl"></i>
              </div>
            </div>
        }
      </div>
      <div className="font-bold mb-2">Rekening Bank (sesuai KTP)</div>
      <SearchableListBox placeholder="Select bank" items={bankList} selectedIndex={selectedBankIndex}
        onSelected={(i, item) => {
          setSelectedBankIndex(i)
          setInputBankCode(item.value);
        }} />
      <div className="flex flex-row items-center mt-2 mb-5">
        <BankAccountField placeholder="Account number" className="flex-1" value={inputBankAccount}
          onChange={(v) => {
            setResultAccountName(null);
            setInputBankAccount(v);
          }} />
        <LoadingButton className="w-40 h-12 ml-2 bg-secondary text-white"
          label="Cek"
          loading={checkBankAccountLoading}
          loadingLabel=""
          onClick={handleValidateAccount} />
      </div>

      {
        resultAccountName !== null &&
        <div className="text-sm text-gray-400 font-bold mb-5">
          <div>Rekening:</div>
          <div>{resultAccountName}&nbsp;<span>{`(${inputBankAccount})`}</span></div>
        </div>
      }

      {
        enableSubmitBank ?
          <LoadingButton label="Simpan" loading={submitBankLoading} loadingLabel="" className="bg-primary text-white w-32 h-12"
            onClick={handleSubmitBank} /> :
          <div className="center font-bold cursor-not-allowed rounded-xl bg-gray-300 text-white w-32 h-12">Simpan</div>
      }


    </div>
  );

  const renderAccountBank = () => (
    <div>
      {renderApprovalStatus()}
      <div className={`flex flex-row items-center flex-1 ${refundStatus === 'ACCOUNT_APPROVED' ? 'text-secondary' : 'text-gray-400'}`}>
        <div className="flex items-center w-10 ">
          <i className="fi fi-br-bank text-2xl"></i>
        </div>
        <div className="mr-5">
          <div className="font-bold">
            {accountName}
            {refundStatus === 'ACCOUNT_APPROVED' && <span className="ml-2 text-primary"><i className="fi fi-br-badge-check"></i></span>}
          </div>
          <div className="text-gray-400 text-sm mb-2">{bankName}<span className="mx-2">&bull;</span>{bankAccount}</div>
        </div>
        {
          refundStatus !== 'UNDER_REVIEW' && 
          <TextButton className="text-sm text-primary" label={refundStatus === 'INVALID_DATA' ? 'Submit Ulang' : 'Ubah'}
            onClick={() => setBankAccount(null)}/>
        }
      </div>
    </div>
  );

  const renderApprovalStatus = () => {
    if(refundStatus === 'UNDER_REVIEW'){
      return(
        <div className="mb-2">
          <div className="bg-yellow-50 px-4 py-4 text-sm rounded-xl flex flex-row">
            <div className="flex flex-row items-center font-bold mr-2">
              <i className="fi fi-br-clock text-lg mr-1"></i>Sedang ditinjau
            </div>
          </div>
        </div>
      );
    }
    if(refundStatus === 'INVALID_DATA'){
      return(
        <div className="mb-2">
          <div className="bg-gray-100 px-4 py-4 text-sm rounded-xl text-gray-400  flex flex-row">
            <div className="flex flex-row items-center font-bold mr-2">
              <i className="fi fi-br-cross-circle text-lg mr-1"></i>Ditolak:
            </div>
            <div>{approvalNote}</div>
          </div>
        </div>
      );
    }
  }


  const renderRefundStatus = () => {
    if(refundStatus === 'PENDING' || refundStatus == 'PROCESS'){
      return(
        <div>Permintaan penarikan dana telah kami terima. Kami akan segera proses dalam 2x24jam.</div>
      );
    }
    else if(refundStatus === 'REFUNDED'){
      return(
        <div>Dana berhasil dikembalikan ke pembeli</div>
      )
    }
    else if(refundStatus === 'FAILED'){
      return(
        <div>Pengembalian dana gagal. Mohon hubungi admin Bylinx untuk diproses.</div>
      )
    }
    else{
      return(
        <div>Menunggu request pengembalian dana</div>
      )
    }
  }



  return (
    <SubPage title="Refund">
      <Toast ref={toast} />

      <div className="mb-10">
        <div className="font-bold mb-5">Dana yang akan dikembalikan:</div>
        {renderRefundAmount()}
        {refundFee !== null && <div className="text-xs italic mt-2">{`Pengembalian dana akan dikenakan biaya ${Lib.formatCurrency(refundFee, false)}`}</div>}
      </div>
      <div className="mb-10">
        <div className="font-bold mb-5">Rekening tujuan:</div>
        {
          bankAccount === null ? renderAccountBankForm() : renderAccountBank()
        }

      </div>

      <div>
        <div className="font-bold mb-5">Status:</div>
        <div>{renderRefundStatus()}</div>
      </div>

      <div className="mt-20">
        {
          refundStatus === 'ACCOUNT_APPROVED' ?
          <LoadingButton className="bg-primary text-white h-12" label="Request Pengembalian Dana"
            onClick={() => {
              handleRequestRefund()
            }}/> :
          <div className="center bg-gray-400 text-white w-full h-12 rounded-xl font-bold cursor-pointer">Request Pengembalian Dana</div>
        }
      </div>


    </SubPage>
  );


}
export default Refund;