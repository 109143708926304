import {useState, useEffect, useRef} from 'react';
import * as Lib from 'utils/Lib';
import ProductPreview from 'components/ProductPreview';

import dummy from 'images/dummy.jpg';



function ItemSummary({
  couponCode = null,
  items = [],
  shippingFee = 0,
  platformFee = 0,
  shippingInsuranceFee = null,
  totalAmount = 0
}) {

  const [expandedMode, setExpandedMode] = useState(false);

  const productPreviewRef = useRef();


  const handleCollapsible = () => {
    setExpandedMode((prev) => setExpandedMode(!prev));
  }



  return(
    <div className="border-2 rounded-xl border-gray-200 px-4">
      <ProductPreview ref={productPreviewRef}/>
      {
        expandedMode ?
        <div>
          <div className="flex flex-row items-center justify-between pt-4 pb-8 cursor-pointer group"
            onClick={handleCollapsible}>
            <div className="text-sm text-gray-400">Items</div>
            <i className="fi fi-br-angle-small-up text-gray-400 group-hover:text-secondary text-2xl"></i>
          </div>

          <div>
            {
              items.map((item, i) => (
                <div key={i} className="flex flex-row items-center mb-4">
                  <div className="flex flex-row flex-1  items-center overflow-hidden">
                    <div className="w-10 h-10 mr-2 cursor-pointer flex-shrink-0" role="button"
                      onClick={() => productPreviewRef.current.show(item.images, item.itemName, item.price, item.weight, 'This is description')}>
                      {
                        item.images.length > 0 ?
                          <img src={Lib.imageURL(item.images[0])} className="w-full h-full object-cover rounded-xl" alt="product image"/> :
                          <div className="center w-10 h-10 rounded-xl bg-green-100">
                            <i className="fi fi-br-box-alt text-sm"></i>
                          </div>
                      }
                    </div>
                    <div className="mr-2 truncate">{item.itemName}</div>
                  </div>
                  <div>{`${item.quantity} x`}</div>
                  <div className="w-32 text-right">{Lib.formatCurrency(item.price, true)}</div>
                </div>
              ))
            }
            <div className="flex flex-row mb-4">
              <div className="flex-1">Ongkos kirim</div>
              <div className="w-32 text-right">{Lib.isFree(shippingFee)}</div>
            </div>
            <div className="flex flex-row mb-4">
              <div className="flex-1">Biaya aplikasi</div>
              <div className="w-32 text-right">{Lib.isFree(5000)}</div>
            </div>
            <div className="flex flex-row mb-4">
              <div className="flex-1">Biaya payment gateway</div>
              <div className="w-32 text-right">{Lib.isFree(5000)}</div>
            </div>
            {
              shippingInsuranceFee !== null &&
              <div className="flex flex-row mb-4">
                <div className="flex-1">Asuransi pengiriman</div>
                <div className="w-32 text-right">{Lib.isFree(shippingInsuranceFee)}</div>
              </div>
            }
          </div>

          <div className='border-b border-gray-200 mb-4'></div>

          <div className="flex flex-row mb-4">
            <div className="flex-1">Jumlah pembayaran</div>
            <div className="w-32 text-right font-bold">{Lib.formatCurrency(totalAmount, true)}</div>
          </div>

        </div> 
        :
        <div className="flex flex-row items-center cursor-pointer py-4 group" 
          onClick={handleCollapsible}>
          <div className="flex-1">
            <div className="text-sm text-gray-400">Jumlah pembayaran</div>
            <div className="text-xl font-bold">{Lib.formatCurrency(totalAmount, true)}</div>
          </div>
          <i className="fi fi-br-angle-small-down text-gray-400 group-hover:text-secondary text-2xl"></i>
        </div>
      }
    </div>
  );
}
export default ItemSummary;