import {useState,  useEffect, forwardRef, useImperativeHandle } from 'react';

function Toast({
  onClose = () => {}
}, ref) {

  const [message, setMessage] = useState('Hello');
  const [visible, setVisible] = useState(false);

  useImperativeHandle(ref, () => ({
    show: (message) => {
      setMessage(message);
      setVisible(true);
    }
  }));

  const handleClose = () => {
    onClose();
    setVisible(false);
  }


  useEffect(() => {
    const timeout =  setTimeout(() => {
      handleClose();
    }, 3000);

    return () => clearTimeout(timeout);
  }, [visible]);

  return(
    visible &&
    <div className="absolute ml-auto mr-auto left-0 right-0 top-5
      flex flex-row items-start
      bg-secondary w-full max-w-xl pl-10 py-5 rounded-2xl">
      <div className="text-white flex-1">{message}</div>
      <div className="flex center px-10 text-green-300 font-bold cursor-pointer" onClick={handleClose}>OK</div>
    </div>
  );
}
export default forwardRef(Toast);