import { useState, useEffect } from 'react';
import paidGreen from 'images/paid-green.png';
import shippingGreen from 'images/shipping-green.png';
import deliveredGreen from 'images/delivered-green.png';
import completedGreen from 'images/completed-green.png';
import paidGray from 'images/paid-gray.png';
import shippingGray from 'images/shipping-gray.png';
import deliveredGray from 'images/delivered-gray.png';
import completedGray from 'images/completed-gray.png';
import check from 'images/check.png';



function TransactionStatus({ orderStatus, shippingStatus }) {

	const [step, setStep] = useState(0);

	useEffect(() => {

		if(orderStatus === 'PAID'){
			setStep(1);
		}

		if(shippingStatus === 'ON_PROCESS'){
			setStep(2);
		}

		if(shippingStatus === 'DELIVERED'){
			setStep(3);
		}

		if(orderStatus === 'COMPLETED'){
			setStep(4);
		}


	}, [orderStatus, shippingStatus]);


	const renderIcon = (initStep, currentStep, greenIcon, grayIcon) => (
		<img className="w-12" src={currentStep >= initStep ? greenIcon : grayIcon} alt="status icon"/>
	);


	const renderCheckPoint = (initStep, currentStep) => {
		if(initStep === 1){
			return(
				<div className={`center w-6 h-6 rounded-full flex-shrink-0 ${currentStep >= initStep ? 'bg-green-300' : 'bg-gray-200'}`}>
					{currentStep >= initStep && <img className="w-3" src={check} alt="check" />}
				</div>
			);
		}
		else{
			return(
				<div className={`flex flex-row items-center w-full`}>
					<div className={`border-t w-full flex-1 ${currentStep >= initStep ? 'border-green-300' : 'border-gray-300'}`}></div>
					<div className={`center w-6 h-6  rounded-full ${currentStep >= initStep ? 'bg-green-300' : 'bg-gray-200'}`}>
						{currentStep >= initStep && <img className="w-3" src={check} alt="check" />}
					</div>
				</div>
			);
		}
	}



	return (
		<div>
			<div className="flex flex-row justify-between mb-2">
				{renderIcon(1, step, paidGreen, paidGray)}
				{renderIcon(2, step, shippingGreen, shippingGray)}
				{renderIcon(3, step, deliveredGreen, deliveredGray)}
				{renderIcon(4, step, completedGreen, completedGray)}
			</div>
			<div className="flex flex-row px-3">
				{renderCheckPoint(1, step)}
				{renderCheckPoint(2, step)}
				{renderCheckPoint(3, step)}
				{renderCheckPoint(4, step)}
			</div>
		</div>
	);
}
export default TransactionStatus;