import { useState, useEffect } from 'react';
import HttpStatus from 'http-status-codes';
import { useNavigate } from 'react-router-dom';
import { getUnreadNotificationCount } from 'service/NotificationService';


function NotificationButton({
  orderUid = null
}) {

  const [unreadExist, setUnreadExist] = useState(false);

  const navigate = useNavigate();


  useEffect(() => {

    getUnreadNotificationCount(orderUid, 
      (status, body) => {

        if(status === HttpStatus.OK){
          setUnreadExist(body.data.unreadCount > 0);
        }

      });

  }, []);


  const renderIndicator = () => (
    <div className="absolute flex top-0 right-0 w-3 h-3">
      <span className="absolute animate-ping inline-flex w-full h-full rounded-full bg-red-300 opacity-75" />
      <span className="relative inline-flex rounded-full w-3 h-3 bg-red-400" />
    </div>
  );

  return(
    <div className="hover:bg-gray-200 rounded-xl p-2 cursor-pointer" role="button"
      onClick={() => navigate('notification', {replace: false})}>
      <div className="center relative w-6 h-6">
        {unreadExist && renderIndicator()}
        <i className="fi fi-br-bell text-secondary text-2xl"></i>
      </div>
    </div>
  );
}
export default NotificationButton;