import { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import HttpStatus from 'http-status-codes';
import SubPage from 'components/SubPage';
import SearchableListBox from 'components/SearchableListBox';
import BankAccountField from 'components/BankAccountField';
import LoadingButton from 'components/LoadingButton';
import Toast from 'components/Toast';
import { getComplain, submitShipmentImage, saveCustomerBank } from 'service/ComplainService';
import { getRefundProcess, getBankList, validateAccount } from 'service/RefundService';
import * as Lib from 'utils/Lib';
import Button from 'components/Button';


function Complain() {

  const { orderUid } = useParams();

  const [step, setStep] = useState(1);
  const [complainStatus, setComplainStatus] = useState(null);
  const [complainDate, setComplainDate] = useState(null);
  const [merchantReceivedDate, setMerchantReceivedDate] = useState(null);
  const [merchantAddressName, setMerchantAddressName] = useState('');
  const [merchantAddressPhone, setMerchantAddressPhone] = useState('');
  const [merchantAddressDetail, setMerchantAddressDetail] = useState('');
  const [customerShipmentImage, setCustomerShipmentImage] = useState(null);

  const [ktpImage, setKtpImage] = useState(null);
  const [bankCode, setBankCode] = useState(null);
  const [bankAccount, setBankAccount] = useState(null);
  const [accountName, setAccountName] = useState(null);
  const [bankName, setBankName] = useState(null);

  const [refundStatus, setRefundStatus] = useState(null);
  const [refundedDate, setRefundedDate] = useState(null);
  const [disburseId, setDisburseId] = useState(null);
  const [referenceNumber, setReferenceNumber] = useState(null);

  const [selectedBankIndex, setSelectedBankIndex] = useState(-1);
  const [bankList, setBankList] = useState([]);

  const [enableSubmitBank, setEnableSubmitBank] = useState(false);

  const [submitShipmentImageLoading, setSubmitShipmentImageLoading] = useState(false);
  const [checkBankAccountLoading, setCheckBankAccountLoading] = useState(false);
  const [submitBankLoading, setSubmitBankLoading] = useState(false);


  const inputShippingRef = useRef();
  const inputIdRef = useRef();
  const toast = useRef();
  const navigate = useNavigate();




  const fetchComplain = () => {

    getComplain(orderUid,
      (status, body) => {

        if (status === HttpStatus.OK) {

          const complain = body.data;

          console.log(complain);

          setComplainStatus(complain.complainStatus);

          setComplainDate(complain.complainDate);
          setMerchantReceivedDate(complain.merchantReceivedDate);
          setMerchantAddressName(complain.merchantAddressName);
          setMerchantAddressPhone(complain.merchantAddressPhone);
          setMerchantAddressDetail(complain.merchantAddressDetail);
          setCustomerShipmentImage(Lib.imageURL(complain.customerShipmentImage));

          setRefundStatus(complain.refundStatus);
          if (refundStatus !== 'INVALID_DATA') {
            setBankCode(complain.bankCode);
            setBankName(complain.bankName);
            setBankAccount(complain.bankAccount);
            setAccountName(complain.accountName);
          }

          setRefundedDate(complain.refundedDate);
          setDisburseId(complain.disburseId);
          setReferenceNumber(complain.referenceNumber);



          if (complain.complainStatus === 'REQUESTED') {
            setStep(1);
          }
          else if (complain.complainStatus === 'MERCHANT_APPROVED' || complain.complainStatus === 'BUYER_SUBMIT_RETURN') {
            setStep(3);
          }
          else if (complain.complainStatus === 'MERCHANT_RECEIVED_RETURN') {
            setStep(5);
          }
          else if (complain.complainStatus === 'REFUNDED_TO_BUYER') {
            setStep(6);
          }




        }
        else if (status === HttpStatus.NOT_FOUND) {
          alert('This order doesnt have complain');
        }


      });
  }

  const fetchBankList = () => {
    getBankList(
      (status, body) => {

        if (status === HttpStatus.OK) {
          setBankList(toListItem(body.data));
        }

      }
    );
  }


  const toListItem = (list) => {

    const listItem = [];

    for (let i = 0; i < list.length; i++) {

      listItem.push({
        label: list[i].bankName,
        value: list[i].bankCode
      });

    }

    return listItem;
  }

  const checkEnableSubmitBank = () => {

    setEnableSubmitBank(false);
    if (ktpImage == null) {
      return;
    }
    if (bankCode === null) {
      return;
    }
    if (bankAccount === null) {
      return;
    }
    if (accountName === null) {
      return;
    }
    setEnableSubmitBank(true);

  }

  useEffect(() => {

    checkEnableSubmitBank()

  }, [accountName, ktpImage]);



  useEffect(() => {

    fetchComplain();
    fetchBankList();


  }, []);


  const handleSelectShippingFile = (e) => {

    const file = e.target.files[0];

    if(file.size < 5000000){
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = async (e) => {

        setCustomerShipmentImage(reader.result);

      };
    }
    else{
      toast.current.show('Ukuran file melebihi 5MB');
    }

    

  }

  const handleSelectIdFile = (e) => {

    const file = e.target.files[0];

    if(file.size < 5000000){
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = async (e) => {

        setKtpImage(reader.result);

      };
    }
    else{
      toast.current.show('Ukuran file melebihi 5MB');
    }

  }

  const handleSubmitShipmentImage = () => {

    setSubmitShipmentImageLoading(true);

    submitShipmentImage(orderUid, customerShipmentImage,
      (status, body) => {

        setSubmitShipmentImageLoading(false);

        if (status === HttpStatus.OK) {
          toast.current.show('Bukti pengiriman berhasil dikirim');
          fetchComplain();
        }

      });

  }

  const handleValidateAccount = () => {

    if (bankCode === null) {
      toast.current.show('Mohon pilih bank');
      return;
    }
    if (bankAccount === null) {
      toast.current.show('Mohon masukkan nomor rekening');
      return;
    }

    setCheckBankAccountLoading(true);

    validateAccount(bankCode, bankAccount,
      (status, body) => {

        setCheckBankAccountLoading(false);

        if (status === HttpStatus.OK) {
          setAccountName(body.data.accountName);
        }
        else {
          setAccountName(null);
          toast.current.show('Rekening tidak valid');
        }

      });

  }


  const handleSubmitBank = () => {

    const customerBank = {
      bankCode, bankAccount, accountName, ktpImage
    }

    setSubmitBankLoading(true);

    saveCustomerBank(orderUid, customerBank,
      (status, body) => {

        setSubmitBankLoading(false);

        if (status === HttpStatus.OK) {
          toast.current.show('Bank submited. Please wait for review');
          fetchComplain();
        }
      });

  }







  const renderDot = (initStep, islastStep) => {
    if (islastStep) {
      return (
        <div className="relative h-full">
          <div className={`dot rounded-full ${step >= initStep ? 'bg-green-200' : 'bg-gray-200'}`}></div>
        </div>
      );
    }
    else {
      return (
        <div className="relative h-full">
          <div className={`dotline h-full ${step > initStep ? 'bg-green-200' : 'bg-gray-200'}`}></div>
          <div className={`dot rounded-full  ${step >= initStep ? 'bg-green-200' : 'bg-gray-200'}`}></div>
        </div>
      );
    }

  }


  const renderStep1 = () => (
    <div className="flex flex-row">
      <div className="w-10">
        {renderDot(1, false)}
      </div>
      <div className={`font-bold mb-10 ${step >= 1 ? 'text-secondary' : 'text-gray-400'}`}>Menunggu konfirmasi seller</div>
    </div>
  );

  const renderStep2 = () => (
    <div className="flex flex-row">
      <div className="w-10">
        {renderDot(2, false)}
      </div>
      <div className={`font-bold mb-10 ${step >= 2 ? 'text-secondary' : 'text-gray-400'}`}>Komplain diterima seller</div>
    </div>
  );
  const renderStep3 = () => (
    <div className="flex flex-row">
      <div className="w-10">
        {renderDot(3, false)}
      </div>
      <div className="w-full mb-10">
        <div className={`font-bold mb-5 ${step >= 3 ? 'text-secondary' : 'text-gray-400'}`}>
          Mengirim barang yang ingin diganti ke seller
        </div>


        {
          step >= 3 &&
          <div>
            {
              merchantAddressDetail === null || merchantAddressDetail === '' ?
                <div className='bg-yellow-50 p-5 rounded-xl'>
                  <div className="text-sm">Seller belum memasukkan alamat retur barang. Mohon hubungi seller bersangkutan atau tim Bylinx.</div>
                </div> :
                <div>
                  <div className="text-sm bg-gray-50 p-4 rounded-xl mb-5">
                    <div className="text-xs text-gray-400 mb-5">Kirim ke:</div>
                    <div className="font-bold mb-2">{merchantAddressName}</div>
                    <div className="mb-2">{merchantAddressPhone}</div>
                    <div className="flex flex-row">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-5 h-5 mr-2">
                        <path d="M12,.042a9.992,9.992,0,0,0-9.981,9.98c0,2.57,1.99,6.592,5.915,11.954a5.034,5.034,0,0,0,8.132,0c3.925-5.362,5.915-9.384,5.915-11.954A9.992,9.992,0,0,0,12,.042ZM12,14a4,4,0,1,1,4-4A4,4,0,0,1,12,14Z" />
                      </svg>
                      <div>{merchantAddressDetail}</div>
                    </div>
                  </div>
                  {

                    complainStatus === 'MERCHANT_APPROVED' ?
                      <div>
                        <div className="font-bold">Kirim bukti pengiriman barang</div>
                        <div className="text-sm text-gray-400 mb-5">JPG, or PNG. Max size of 5 MB</div>
                        <div>
                          {
                            customerShipmentImage === null ?
                              <div className="w-full h-40 border-dashed border-4 border-gray-100 center rounded-xl cursor-pointer"
                                onClick={() => inputShippingRef.current.click()}>
                                <i className="fi fi-br-camera text-2xl text-gray-400 "></i>
                                <input className="hidden" type="file" accept=".jpg,.jpeg,.png" ref={inputShippingRef} onChange={handleSelectShippingFile} />
                              </div> :
                              <div>
                                <div className="relative w-full h-40 overflow-hidden border-dashed border-4 border-gray-100 center rounded-xl mb-5">

                                  <img className="w-full h-full object-contain" src={customerShipmentImage} />

                                  <div className="absolute top-2 right-2 w-8 h-8 rounded-full bg-secondary flex center cursor-pointer"
                                    onClick={() => setCustomerShipmentImage(null)}>
                                    <i className="fi fi-br-cross-small text-white text-xl"></i>
                                  </div>
                                </div>
                                <LoadingButton className="bg-primary text-white w-32 h-10"
                                  label="Submit"
                                  loading={submitShipmentImageLoading}
                                  loadingLabel=""
                                  onClick={handleSubmitShipmentImage} />
                              </div>
                          }
                        </div>
                      </div> :
                      <div>
                        <div className='text-sm mb-2'>Bukti pengiriman retur barang:</div>
                        <div className="w-full h-40 overflow-hidden border-dashed border-4 border-gray-100 center rounded-xl mb-5">
                          <img className="w-full h-full object-contain" src={customerShipmentImage} />
                        </div>
                      </div>
                  }
                </div>
            }

          </div>
        }


      </div>
    </div>
  );

  const renderStep4 = () => (
    <div className="flex flex-row">
      <div className="w-10">
        {renderDot(4, false)}
      </div>
      <div className="w-full mb-10">
        <div className={`font-bold mb-5 ${step >= 4 ? 'text-secondary' : 'text-gray-400'}`}>Seller menerima pengembalian barang</div>
        {
          step >= 5 &&
          <div>{Lib.dateTime(merchantReceivedDate)}</div>
        }
      </div>
    </div>
  );

  const renderStep5 = () => (
    <div className="flex flex-row">
      <div className="w-10">
        {renderDot(5, false)}
      </div>
      <div className="w-full mb-10">
        <div className={`font-bold mb-5 ${step >= 5 ? 'text-secondary' : 'text-gray-400'}`}>
          Proses pengembalian dana
        </div>

        {
          step >= 5 &&
          <div>
            <Button label='Proses Refund' onClick={() => navigate(`/${orderUid}/refund`, {replace: false})}/>
          </div>
        }



      </div>
    </div>
  );

  const renderStep6 = () => (
    <div className="flex flex-row">
      <div className="w-10">
        {renderDot(6, true)}
      </div>
      <div className="w-full mb-10">
        <div className={`font-bold mb-5 ${step >= 6 ? 'text-secondary' : 'text-gray-400'}`}>Pengembalian dana berhasil</div>
        {
          step >= 6 &&
          <div className="text-gray-400 font-bold">
            <div className="text-sm font-normal text-gray-400">09/12/2021 &bull; 16:03</div>
            <div>Ke Bank BCA (6041321879)</div>
            <div>a/n Happy Indra Wijaya</div>
          </div>
        }
      </div>
    </div>
  );




  const renderTimeline = () => (
    <div>
      {renderStep1()}
      {renderStep2()}
      {renderStep3()}
      {renderStep4()}
      {renderStep5()}
      {renderStep6()}
    </div>
  );





  return (
    <SubPage title="Complain">

      {
        complainStatus === null ?
          <div>Loading complain..</div> :
          <div>
            <Toast ref={toast} />
          <div className="flex flex-row items-center mb-10 font-bold p-4 bg-yellow-100 rounded-xl">
            <i className="fi fi-br-shield-check text-xl mr-2"></i>
            <div>Solusi: Pengembalian Dana</div>
          </div>


            {renderTimeline()}
          </div>
      }


    </SubPage>
  );

}
export default Complain;