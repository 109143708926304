
function TextArea(
  {
    label,
    value,
    placeholder,
    maxLength = 100,
    className = 'h-20',
    onChange = (value) => { }
  }) {

  return(
    <div className="w-full">
      <div className="text-gray-400 text-sm">{label}</div>
      <textarea className={`w-full mt-1 p-5 rounded-xl outline-none font-bold text-sm border border-gray-200
        focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-20 
        box-border placeholder-gray-400 ${className}`}
        type="text" value={value} placeholder={placeholder} maxLength={maxLength}
        onChange={(e) => onChange(e.target.value)}/>
    </div>
  );
}
export default TextArea;