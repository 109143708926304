import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import noLocation from 'images/icon-no-location.png';
import location from 'images/icon-location.png';



export default function Maps({
  givenPosition = null,
  onSelectedPosition = (position) => {}
}) {

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyB-qQKf-DaGj409ujxi_XfswzWnb8VBq_U',
    libraries: ['places']
  });

  if(!isLoaded){
    return(
      <div className="w-full h-96 bg-green-50">Memuat map...</div>
    )
  }

  return <MapComponent givenPosition={givenPosition} onSelectedPosition={(latLng) => onSelectedPosition(latLng)}/>
}



function MapComponent({
  givenPosition = null,
  onSelectedPosition = (position) => {}
}){

  const mapRef = useRef();
  const geocoder = useMemo(() => (new window.google.maps.Geocoder()), []);
  const defaultCenter = useMemo(() => ({ lat: -6.175200401540207, lng: 106.82721180858991 }), []);
  const mapOptions = useMemo(
    () => ({
      clickableIcons: false,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
      zoomControl: false,
    }),
    []
  );
  const markerOptions = useMemo(
    () => ({
      animation: window.google.maps.Animation.DROP,
    }),
    []
  );
  const onLoad = useCallback(map => (mapRef.current = map), []);
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: ['ID']
      }
    },
    debounce: 300,
  });
  const searchRef = useOnclickOutside(() => clearSuggestions());


  const [markerPosition, setMarkerPosition] = useState(givenPosition === null ? defaultCenter : givenPosition);        // position = latLng
  const [selectedAddress, setSelectedAddress] = useState(null);   // formatted_address


  

  
  const reverseGeocode = (latlng) => {


    geocoder.geocode({location: latlng})
    .then((response) => {

      if(response.results[0]){
        
        setSelectedAddress(response.results[0].formatted_address);
      }

    })


  }


  const handleSelect = ({description}) => {

    setValue(description, false);

    clearSuggestions();

    getGeocode({ address: description }).then((results) => {

      const latLng = getLatLng(results[0]);   // { lat, lng }

      console.log(latLng);

      mapRef.current.panTo(latLng);
      setMarkerPosition(latLng);
      reverseGeocode(latLng);
      
      onSelectedPosition(latLng);

    });

  }


  useEffect(() => {
    if(givenPosition !== null){
      reverseGeocode(givenPosition);
    }
  }, [givenPosition]);




  const renderSuggestions = () => (
    <div className="absolute top-12 left-0 right-0 
      bg-white border border-gray-300 rounded-xl z-20 shadow-sm px-5 py-2">
      <div className="max-h-40 mb-2 overflow-y-auto">
        {
          data.map((suggestion) => {

            const {
              place_id,
              structured_formatting: { main_text, secondary_text },
            } = suggestion;

            return(
              <div key={place_id} className="text-gray-400 cursor-pointer text-sm hover:text-secondary py-2"
                  onClick={() => handleSelect(suggestion)}>
                <strong>{main_text}</strong> <small>{secondary_text}</small>
              </div>
            );

          }) 
        }
      </div>
      <div className="w-full text-right text-xs ">powered by Google</div>
    </div>
  );

  const renderSearch = () => (
    <div ref={searchRef} className="relative w-full max-w-xs">
      <input
        className="w-full h-10 mt-1 px-5 rounded-xl outline-none font-bold text-xs border border-gray-200
      focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-20 
        box-border placeholder-gray-300"
        type="text" 
        value={value} 
        placeholder="Cari"
        onChange={(e) => setValue(e.target.value)} />
      
      {status === 'OK' && renderSuggestions()}
    </div>
  );

  
  



  return (
    <div className="w-full">
      <div className="relative w-full h-96">
        <GoogleMap mapContainerClassName="w-full h-full"
          center={givenPosition === null ? defaultCenter : givenPosition} zoom={16} options={mapOptions}
          onLoad={onLoad}
          onClick={(e) => {

            setValue('', false);

            const latLng = {
              lat: e.latLng.lat(),
              lng: e.latLng.lng()
            }

            console.log(latLng);

            mapRef.current.panTo(latLng);
            setMarkerPosition(latLng);
            reverseGeocode(latLng);

            onSelectedPosition(latLng);

          }}>
          <Marker options={markerOptions} position={markerPosition} />
        </GoogleMap>
        <div className="absolute top-2 left-2 right-2 ">
          {renderSearch()}
        </div>
      </div>
      <div className="border rounded-xl px-2 py-4 my-2">
        {
          selectedAddress === null ?
          <div className="flex flex-row items-center w-full ">
            <img className="w-5 mr-2" src={noLocation} alt="no location icon" />
            <div className="text-sm text-gray-400 truncate">Klik di map untuk tentukan pinpoint</div>
          </div> :
          <div className="flex flex-row items-center w-full">
            <img className="w-4 mr-2" src={location} alt="no location icon" />
            <div className="text-sm truncate">{selectedAddress}</div>
          </div>
        }
      </div>
      <div className="text-xs text-gray-400 italic">* Pastikan titik pinpoint sudah sesuai alamat kamu</div>
    </div>
  )

}