import {useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import NotificationButton from './NotificationButton';
import Footer from './Footer';


function Page({
  merchantImage = null,
  merchantName = '',
  children
}) {

  const { orderUid } = useParams();



  const renderMerchantLogo = () => {
    if(merchantImage === null){
      return(
        <div className="absolute flex center left-0 right-0 mx-auto -top-14
        bg-green-200 w-28 h-28 rounded-full shadow-xl">
          <i className="fi fi-br-shop text-secondary text-4xl"></i>
        </div>
      )
    }
    else{
      return(
        <div className="absolute flex center left-0 right-0 mx-auto -top-14
        bg-green-200 w-28 h-28 rounded-full shadow-xl overflow-hidden">
          <img className="w-full" src={merchantImage} alt="profile picture"/>
        </div>
      )
    }
  }


  return(
    <div className="w-full">
      <div className="relative bg-white w-full max-w-xl mx-auto
        mt-20 mb-10 pb-5 rounded-t-4xl zigzag">
        
        {renderMerchantLogo()}

        <div className="static flex flex-row justify-end py-6 px-8">
          <NotificationButton orderUid={orderUid}/>
        </div>

        <div className="flex flex-row justify-center text-2xl font-bold mb-10">
          {merchantName}
        </div>

        <div className="mb-10">
          {children}
        </div>

        <Footer/>

      </div>

    </div>
  );
}
export default Page;