import {useState, useRef} from 'react';
import HttpStatus from 'http-status-codes';
import LoadingButton from './LoadingButton';
import IconButton from './IconButton';
import ListBox from './ListBox';
import TextArea from './TextArea';
import Message from './Message';
import { requestComplain } from 'service/ComplainService';


function ComplainForm({
  visible = false,
  orderUid = null,
  onClose = () => {},
  onSubmit = () => {}
}){

  const [problemCategoryIndex, setProblemCategoryIndex] = useState(-1);
  const [complainDetail, setComplainDetail] = useState('');
  const [images, setImages] = useState([]);
  const [message, setMessage] = useState('');

  const [submitLoading, setSubmitLoading] = useState(false);


  const inputRef = useRef();
  


  const problemCatagories = [
    {
      label: 'Barang rusak',
      value: 'ORDER_DAMAGED'
    },
    {
      label: 'Barang tidak sesuai',
      value: 'ORDER_NOT_MATCH'
    },
    {
      label: 'Lain-lain',
      value: 'OTHER'
    }
  ];

  const clearField = () => {
    setProblemCategoryIndex(-1);
    setComplainDetail('');
    setImages([]);
    setMessage('');
  }


  const handleClose = () => {
    clearField();
    onClose();
  }

  const handleSelectFile = (e) => {

    setMessage('');

    const file = e.target.files[0];

    if(file.size < 2000000){
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = async (e) => { 

        setImages(images => [...images, reader.result]);
        
      };
    }
    else{
      setMessage('Ukuran file melebihi 2MB');
    }

  }


  const handleRemoveFile = (index) => {
    setImages(images => images.filter((image, i) => i !== index));
  }


  const handleSubmitComplain = () => {

    if(problemCategoryIndex < 0){
      setMessage('Mohon pilih kategori masalah');
      return;
    }
    if(complainDetail === ''){
      setMessage('Mohon isi dengan detail kendala anda');
      return;
    }

    setSubmitLoading(true);

    const complain = {
      orderUid: orderUid,
      complainCategory: problemCatagories[problemCategoryIndex].value,
      expectedSolution: 'REFUND',
      complainDetail: complainDetail,
      complainImages: images
    };

    requestComplain(complain, 
      (status, body) => {

        setSubmitLoading(false);

        if(status === HttpStatus.OK){
          onSubmit();
        }
        else{
          setMessage('Gagal submit komplain. Pastikan semua input diisi dengan benar.');
        }
      });

  }



  const renderInputFile = () => (
    <div className="w-20 h-20 border-dashed border-4 border-gray-100 center rounded-xl cursor-pointer" 
      onClick={() => inputRef.current.click()}>
      <i className="fi fi-br-camera text-2xl text-gray-400 "></i>
      <input className="hidden" type="file" accept=".jpg,.jpeg,.png" ref={inputRef} onChange={handleSelectFile} />
    </div>
  );

  const renderProductPhotoDiv = () => (
    <div className="mb-10">
      <div className="font-bold">Foto produk</div>
      <p className="text-gray-400 text-sm mb-5">JPG, or PNG. Ukuran maks 2 MB <span className="mx-1">&bull;</span> <span>{`${images.length}/5`}</span></p>
      <div className="grid grid-cols-5 gap ">
      {
        images.map((image, i) => (
          <div key={i} className="relative w-20 h-20 rounded-xl overflow-hidden cursor-pointer group"
            onClick={() => handleRemoveFile(i)}>
            <img className="w-full h-full object-cover" src={image}/>
            <div className="absolute left-0 right-0 top-0 bottom-0 bg-black bg-opacity-70 hidden group-hover:flex center">
              <i className="fi fi-br-trash text-white text-2xl"></i>
            </div>
          </div>
        ))
      }
      {(images.length < 5) && renderInputFile()}
      </div>
      
    </div>
  );





  return(
    visible &&
    <div className="fixed flex flex-col items-center top-0 bottom-0 left-0 right-0 z-10 bg-black bg-opacity-75">

      <div className="bg-white w-full max-w-lg px-6 py-8 my-10 sm:my-24 rounded-xl overflow-y-auto">

        <div className="flex flex-row items-center mb-5">
          <i className="fi fi-br-shield-exclamation text-4xl mr-2"></i>
          <div className="font-bold text-xl flex-1">Komplain</div>
          <IconButton icon={<i className="fi fi-br-cross-small text-2xl" onClick={handleClose}></i>} />
        </div>

        <div className="flex flex-row items-center mb-5 font-bold p-4 bg-yellow-100 rounded-xl">
          <i className="fi fi-br-shield-check text-xl mr-2"></i>
          <div>Solusi: Pengembalian Dana</div>
        </div>

        <div className="mb-5">
          <Message message={message} messageType="WARN"/>
        </div>

        <div className="mb-5">
          <div className="font-bold mb-2">kategori masalah</div>
          <ListBox selectedIndex={problemCategoryIndex} placeholder="Pilih kategori masalah"
            items={problemCatagories}
            onSelected={(i, item) => setProblemCategoryIndex(i)} />
        </div>

        <div className="mb-5">
          <div className="font-bold mb-2">Deskripsi masalah</div>
          <TextArea value={complainDetail} onChange={(val) => setComplainDetail(val)} />
        </div>

        {renderProductPhotoDiv()}

        <div className="flex justify-end">
          <LoadingButton className="bg-primary text-white h-12 w-32"
            label="Submit"
            loading={submitLoading}
            loadingLabel=""
            onClick={handleSubmitComplain} />
        </div>

      </div>

    </div>
  );
}
export default ComplainForm;

