
function CheckBox({
  label = null,
  checked = false,
  className = '',
  onChange = (val) => {}
}) {

  const handleChange = () => {
    onChange(!checked);
  }

  
  return(
    <div className={`flex flex-row items-center cursor-pointer ${className}`} 
      onClick={handleChange}>
      {
        checked ? 
        <i className="fi fi-br-checkbox text-xl text-green-400 mr-2"></i> : 
        <i className="fi fi-br-stop text-xl text-gray-400 mr-2"></i>
      }      
      {label !== null && <div className={checked ? 'text-secondary' : 'text-gray-400'}>{label}</div>}
    </div>
  );
   
}
export default CheckBox;