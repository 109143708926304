import axios from 'axios';
import * as Lib from 'utils/Lib';


export const requiredVerify = async (orderUid, callback) => {

  try{

    const response = await axios.get(Lib.requestURL(`/api/order/${orderUid}/required-verify`), Lib.requestHeader());
    const responseBody = await response.data;

    callback(response.status, responseBody);

  }
  catch(error){
    Lib.requestErrorHandler(error, callback);
  }

}


export const requestOTP = async (phoneNumber, callback) => {

  try{

    const body = {
      phoneNumber
    }

    const response = await axios.post(Lib.requestURL('/auth/customer/request-otp'), body, Lib.requestHeader());
    const responseBody = await response.data;

    callback(response.status, responseBody);

  }
  catch(error){
    Lib.requestErrorHandler(error, callback);
  }

}

export const verifyOTP = async (orderUid, phoneNumber, otp, callback) => {

  try{

    const body = {
      'orderUid': orderUid, 
      'phoneNumber': phoneNumber, 
      'otp': otp
    }

    const response = await axios.post(Lib.requestURL('/auth/customer/verify-otp'), body, Lib.requestHeader());
    const responseBody = await response.data;

    setSession(responseBody.data.sessionToken);

    callback(response.status, responseBody);

  }
  catch(error){
    Lib.requestErrorHandler(error, callback);
  }

}

const setSession = (token) => {

  localStorage.setItem("SESSION-TOKEN", token);

}

export const getSession = () => {

  const sessionToken = localStorage.getItem("SESSION-TOKEN");

  return sessionToken;
}

export const clearSession = () => {
  localStorage.clear();
}