import axios from 'axios';
import * as Lib from 'utils/Lib';

export const checkout = async (orderUid, callback) => {

  try{

    const body = {
      orderUid
    }

    const response = await axios.post(Lib.requestURL(`/api/c/order/${orderUid}/checkout`), body, Lib.requestHeader());
    const responseBody = await response.data;

    callback(response.status, responseBody);
  }
  catch(error){
    Lib.requestErrorHandler(error, callback);
  }

}

export const afterCheckout = async (merchantOrderId, reference, resultCode, callback) => {

  try{

    const response = await axios.get(Lib.requestURL('/api/notification/payment-redirect?') + 
      Lib.createParameter({merchantOrderId, reference, resultCode}), Lib.requestHeader());
    const responseBody = await response.data;

    callback(response.status, responseBody);
  }
  catch(error){
    Lib.requestErrorHandler(error, callback);
  }
}