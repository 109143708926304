import HttpStatus from 'http-status-codes';
import moment from 'moment-timezone';
import queryString from 'query-string';
import { getSession } from 'service/AuthService';


// const SERVER_URL = 'http://localhost:8080';
const SERVER_URL = 'https://dev.bylinx.id';

const LOCAL_TIMEZONE = moment.tz.guess();

const BUCKET_URL = 'https://bylinx-spaces.sgp1.digitaloceanspaces.com/';


export function requestURL(endpoint) {
  return SERVER_URL + endpoint;
}

export function requestHeader() {

  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }

  const token = getSession();

  if (token !== null) {
    headers['Authorization'] = 'Bearer ' + token;
  }


  return { headers };
}

export function requestErrorHandler(error, callback) {
    
  if (error.response) {

      const responseStatus = error.response.status;

      if(responseStatus === HttpStatus.UNAUTHORIZED){

        let currentpath = window.location.pathname;

        if(currentpath.endsWith('/')){
          currentpath = currentpath.slice(0, -1);
        }

        window.location = currentpath + '/verify';

        return;
      }
      

      if(responseStatus >= 500){
          console.log("Something went wrong.");
      }

      console.log(error);
      console.log(error.response.data);

      callback(responseStatus, error.response.data);
    
  }

}


// get parameter from query string
export function getParameter(context, key) {
  const parameters = queryString.parse(context.location.search);
  return parameters[key] || null;
}

export function createParameter(objectParameter){
  return queryString.stringify(objectParameter);
}

export function checkParemeter(context, key){
  const parameters = queryString.parse(context.location.search);
  return key in parameters;
}

export function now() {
  return moment.utc();
}

export function formatDate(date, format){
  if(date === null){
    return '';
  }

  return moment.utc(date).tz(LOCAL_TIMEZONE).format(format);
}

export function date(date){
  return formatDate(date, "DD MMM YYYY");
}

export function dateTime(date){
  return formatDate(date, "DD MMM YYYY, HH:mm zz");
}

export function shortDateTime(date){
  return formatDate(date, 'DD MMM, HH:mm');
}

export function dateIso(date){
  return formatDate(date, "YYYY-MM-DD");
}


export function formatCurrency(num, withPrefix = false) {
  if (num === null) {
      return '-';
  }

  const formatted = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  if(withPrefix){
      return 'Rp ' + formatted;
  }

  return formatted;
}

export const isFree = (amount) => {
  if(amount === 0){
    return 'Free';
  }
  else{
    return formatCurrency(amount, true);
  }
}

export function imageURL(imageKey){
  if(imageKey !== null){
    return BUCKET_URL + imageKey;
  }
  return null;
}

export function blurPhone(phone){

  if(phone === null){
    return 'xx';
  }

  return `xx${phone.substring(phone.length - 3, phone.length)}`;

}

export function toKilo(weight){

  if(weight === null){
    return 0;
  }

  return Math.round(weight / 1000);

}


export function formatWeight(weight){

  if(weight === null){
    return '-';
  }

  if(weight < 1000){
    return `${formatCurrency(weight, false)} g`;
  }
  
  return `${formatCurrency(toKilo(weight), false)} kg`;

}