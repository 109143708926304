import { useNavigate } from 'react-router-dom';
import notfound from "images/notfound.png";
import Button from "components/Button";



function OrderNotFound(){

  const navigate = useNavigate();



  return(
    <div>
      <div className="flex flex-col items-center my-20">
        <div>
          <div className="flex items-center mb-2">
            <img className="w-20 h-20" src={notfound} alt="not-found"/>
            <div className="text-2xl font-bold ml-2">404</div>
          </div>
          <div className="font-bold mb-5">Halaman tidak ditemukan.</div>

        </div>
      </div>
    </div>
  );
}
export default OrderNotFound;