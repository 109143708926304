import axios from 'axios';
import * as Lib from 'utils/Lib';


export const getRefundProcess = async (orderUid, callback) => {

  try {

    const response = await axios.get(Lib.requestURL(`/api/c/order/${orderUid}/refund`), Lib.requestHeader());
    const responseBody = await response.data;

    callback(response.status, responseBody);

  }
  catch (error) {
    Lib.requestErrorHandler(error, callback);

  }

}


export const getBankList = async (callback) => {

  try {

    const response = await axios.get(Lib.requestURL('/api/payout/bank-list'), Lib.requestHeader());
    const responseBody = await response.data;

    callback(response.status, responseBody);

  }
  catch (error) {
    Lib.requestErrorHandler(error, callback);

  }

}

export const validateAccount = async (bankCode, bankAccount, callback) => {

  try {

    const params = {bankCode, bankAccount};

    const response = await axios.get(Lib.requestURL('/api/payout/validate-account?' + Lib.createParameter(params)), Lib.requestHeader());
    const responseBody = await response.data;

    callback(response.status, responseBody);

  }
  catch (error) {
    Lib.requestErrorHandler(error, callback);

  }


}

export const saveCustomerBank = async (orderUid, customerBank, callback) => {

  try {

    const response = await axios.put(Lib.requestURL(`/api/c/order/${orderUid}/refund/bank`), customerBank, Lib.requestHeader());
    const responseBody = await response.data;

    callback(response.status, responseBody);

  }
  catch (error) {
    Lib.requestErrorHandler(error, callback);
  }

}

export const requestRefund = async (orderUid, refundProcessId, callback) => {

  try {

    const body = {
      'refundProcessId': refundProcessId
    }

    const response = await axios.put(Lib.requestURL(`/api/c/order/${orderUid}/refund/request`), body, Lib.requestHeader());
    const responseBody = await response.data;

    callback(response.status, responseBody);

  }
  catch (error) {
    Lib.requestErrorHandler(error, callback);
  }

}
