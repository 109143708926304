import axios from 'axios';
import * as Lib from 'utils/Lib';



export const getUnreadNotificationCount = async (orderUid, callback) => {

  try{

    const response = await axios.get(Lib.requestURL(`/api/c/order/${orderUid}/notification/unread-count`), Lib.requestHeader());
    const responseBody = await response.data;

    callback(response.status, responseBody);

  }
  catch(error){
    Lib.requestErrorHandler(error, callback);
  }

}

export const getNotificationList = async (orderUid, callback) => {

  try{

    const response = await axios.get(Lib.requestURL(`/api/c/order/${orderUid}/notification`), Lib.requestHeader());
    const responseBody = await response.data;

    callback(response.status, responseBody);

  }
  catch(error){
    Lib.requestErrorHandler(error, callback);
  }

}

export const markedAsReadNotification = async (orderUid, notificationId, callback) => {

  try{

    const params = {
      notificationId: notificationId
    }

    const response = await axios.put(Lib.requestURL(`/api/c/order/${orderUid}/notification/read?${Lib.createParameter(params)}`), null, Lib.requestHeader());
    const responseBody = await response.data;

    callback(response.status, responseBody);

  }
  catch(error){
    Lib.requestErrorHandler(error, callback);
  }

}