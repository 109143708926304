import bylinxLogo from 'images/bylinx-logo.png';
import duitkuLogo from 'images/duitku-logo.png';


function Footer() {


  return(
    <div className="flex-col center mb-10">
      <a href="https://bylinx.id" target="_blank">
        <img className="w-32" src={bylinxLogo} alt="Bylinx logo"/>
      </a>
      <div className="center">
        <div className="text-gray-400 text-xs mr-2">Powered by:</div>
        <a href="https://duitku.com" target="_blank">
          <img className="w-16" src={duitkuLogo} alt="Duitku logo"/>
        </a>
      </div>
    </div>
  )
  
}
export default Footer;