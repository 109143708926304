import IconButton from "./IconButton";
import Button from "./Button";


function ConfirmationComplete({
  visible = false,
  onComplete = () => {},
  onClose = () => {}
}){



  const handleClose = () => {
    onClose();
  }

  return(
    visible &&
    <div className="fixed flex flex-col items-center top-0 bottom-0 left-0 right-0 z-10 bg-black bg-opacity-75" 
      onClick={handleClose}>
      
      <div className="bg-white w-full max-w-lg px-6 py-8 rounded-xl mt-60" 
        onClick={(e) => e.stopPropagation()}>
        
        <div className="flex flex-row items-center mb-5">
          <div className="text-lg font-bold flex-1">Selesaikan Order</div>
          <IconButton icon={<i className="fi fi-br-cross-small text-2xl"></i>} onClick={handleClose}/>
        </div>

        <div className="bg-green-50 px-2 py-4 flex flex-row items-center mb-10 rounded-lg">
          <i className="fi fi-br-info text-xl mr-2"></i>
          <div className="text-sm">
            Pastikan kondisi produk Anda sudah sesuai dan baik. <br/>
            Anda tidak dapat mengajukan pengembalian barang atau dana setelah melakukan klik <span className="font-bold">Complete</span>.
          </div>
        </div>


        <div className="center">
          <Button label="Selesai" className="w-40 bg-primary text-white h-12" onClick={onComplete}/>
        </div>


      </div>

    </div>
  );


}
export default ConfirmationComplete;