

function TextField({
  label,
  value,
  placeholder,
  maxLength = 100,
  className,
  onChange = (value) => { }
}) {

  return (
    <div className="w-full">
      <div className="text-gray-400 text-sm">{label}</div>
      <input className={`w-full h-12 mt-1 px-5 rounded-xl outline-none font-bold text-sm border border-gray-200
        focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-20 
        box-border placeholder-gray-300 ${className}`}
        type="text" value={value} placeholder={placeholder} maxLength={maxLength}
        onChange={(e) => onChange(e.target.value)}/>
    </div>
  );

}
export default TextField;
