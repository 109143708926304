
function PhoneField({
  label = 'Phone',
  placeholder = '',
  value = '',
  className = '',
  onChange = (value) => { }
}) {


  return(
    <div className={'w-full ' + className}>
        <label className="text-gray-400 text-sm">{label}</label>
        <div className="flex flex-row items-center w-full h-12 mt-1 px-5 rounded-xl  shadow-sm border border-gray-200
        focus-within:border-primary focus-within:ring focus-within:ring-primary focus-within:ring-opacity-20 
        box-border placeholder-gray-400">
          <div className="font-bold text-gray-400 text-sm">+62</div>
          <input className="text-sm font-bold border-none outline-none focus:ring-0 bg-transparent w-full pl-5"
            type="text" placeholder={placeholder} value={value} maxLength="13"
            onChange={e => {

              const regexp = /^((?!(0))[0-9]{0,12})$/;   // given number without leading 0 and length is 12
              
              let val = e.target.value;
              if (val === '' || regexp.test(val)) {
                onChange(val);
              }
            }}/>
        </div>
      </div>
  );

}
export default PhoneField;