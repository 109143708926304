import {useState, useEffect} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import HttpStatus from 'http-status-codes';
import SubPage from 'components/SubPage';
import { getNotificationList, markedAsReadNotification } from 'service/NotificationService';
import * as Lib from 'utils/Lib';


function Notification() {

  const { orderUid  } = useParams();

  const [notificationList, setNotificationList] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {

    fetchNotification();

  }, []);


  const fetchNotification = () => {

    setLoading(true);

    getNotificationList(orderUid, 
      (status, body) => {

        setLoading(false);

        if(status === HttpStatus.OK){
          setNotificationList(body.data);
        }

      });

  }

  const handleClickNotification = (n) => {

    markedAsReadNotification(orderUid, n.notificationId, 
      (status, body) => {

        if(status === HttpStatus.OK){
          fetchNotification();
        }

      });

  }


  const renderCategory = (category) => {
    if(category === 'INFO'){
      return(
        <div className={`flex flex-row items-center text-blue-400`}>
          <i className="fi fi-br-megaphone text-sm mr-1"></i>
          <div className="text-xs">{category}</div>
        </div>
      );
    }
    else if(category === 'PROMO'){
      return(
        <div className={`flex flex-row items-center text-green-400`}>
          <i className="fi fi-br-badge-percent text-sm mr-1"></i>
          <div className="text-xs">{category}</div>
        </div>
      );
    }
    else{
      return(
        <div className={`flex flex-row items-center`}>
          <i className="fi fi-br-megaphone text-sm mr-1"></i>
          <div className="text-xs">{category}</div>
        </div>
      );
    }
  }

  const renderNotificationList = () => {
    if(notificationList.length === 0){
      return(
        <div className="text-gray-400 text-sm italic">Belum ada notifikasi</div>
      );
    }
    else{
      return(
        <div>
          {
            notificationList.map((n, i) => (
              <div key={n.notificationId} className="px-2 mb-8 cursor-pointer" 
                onClick={() => handleClickNotification(n)}>
                <div className="flex flex-row items-center mb-1">
                  {renderCategory(n.category)}
                  <span className="mx-2">&bull;</span>
                  <div className="text-xs text-gray-400">{Lib.shortDateTime(n.notificationDate)}</div>
                </div>
                <div className={`text-sm ${!n.read && 'font-bold'}`}>{n.title}</div>
                <div className={`text-xs ${n.read && 'text-gray-400'}`}>{n.detail}</div>
              </div>
            ))
          }
        </div>
      );
    }
  }




  return(
    <SubPage title="Notifikasi">

      {
        loading ?
        <div className="text-gray-400 italic text-sm">Memuat notifikasi...</div> :
        renderNotificationList()
      }

    </SubPage>
  );

}
export default Notification;