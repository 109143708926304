import axios from 'axios';
import * as Lib from 'utils/Lib';


export const requestComplain = async (complain, callback) => {

  try{

    const response = await axios.post(Lib.requestURL(`/api/c/order/${complain.orderUid}/complain`), complain, Lib.requestHeader());
    const responseBody = await response.data;

    callback(response.status, responseBody);
  }
  catch(error){
    Lib.requestErrorHandler(error, callback);
  }


}

export const getComplain = async (orderUid, callback) => {

  try{
    
    const response = await axios.get(Lib.requestURL(`/api/c/order/${orderUid}/complain`), Lib.requestHeader());
    const responseBody = await response.data;

    callback(response.status, responseBody);
  }
  catch(error){
    Lib.requestErrorHandler(error, callback);
  }

}

export const submitShipmentImage = async (orderUid, imageBase64, callback) => {

  try{

    const body = {
      imageBase64: imageBase64
    }
    
    const response = await axios.put(Lib.requestURL(`/api/c/order/${orderUid}/complain-shipment-image`), body, Lib.requestHeader());
    const responseBody = await response.data;

    callback(response.status, responseBody);
  }
  catch(error){
    Lib.requestErrorHandler(error, callback);
  }

}


export const validateAccount = async (bankCode, bankAccount, callback) => {

  try {

    const params = {bankCode, bankAccount};

    const response = await axios.get(Lib.requestURL('/api/payout/validate-account?' + Lib.createParameter(params)), Lib.requestHeader());
    const responseBody = await response.data;

    callback(response.status, responseBody);

  }
  catch (error) {
    Lib.requestErrorHandler(error, callback);

  }


}

export const saveCustomerBank = async (orderUid, customerBank, callback) => {

  try {

    const response = await axios.put(Lib.requestURL(`/api/c/order/${orderUid}/bank-refund`), customerBank, Lib.requestHeader());
    const responseBody = await response.data;

    callback(response.status, responseBody);

  }
  catch (error) {
    Lib.requestErrorHandler(error, callback);
  }

}
