
function BankAccountField({
  label,
  value,
  placeholder,
  className,
  onChange = (value) => { }
}) {

  return (
    <div className="w-full">
      <div className="text-gray-400 text-sm">{label}</div>
      <input className={`w-full h-12 mt-1 px-5 rounded-xl outline-none font-bold text-sm border border-gray-200
        focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-20 
        box-border placeholder-gray-300 ${className}`}
        type="text" value={value == null ? '' : value} placeholder={placeholder}
        onChange={e => {

          const regexp = /^[0-9]{0,20}$/;
          
          let val = e.target.value;
          if (val === '' || regexp.test(val)) {
            onChange(val);
          }
        }}/>
    </div>
  );

}
export default BankAccountField;
