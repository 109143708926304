
function DownloadInvoiceButton({invoiceNumber}){

  return(
    <div className="flex flex-row cursor-pointer">
      <div className="font-bold text-primary mr-2">{invoiceNumber}</div>
      <i className="fi fi-br-download text-base"></i>
    </div>
  );
}
export default DownloadInvoiceButton;