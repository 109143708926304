

function Button({
  label = 'ACTION',
  className = 'bg-secondary text-white w-full h-12',
  onClick = () => { }
}) {

  return (
    <div className={`center font-bold cursor-pointer rounded-xl shadow-sm hover:bg-opacity-90 ${className}`}
      onClick={() => onClick()}>
      <div>{label}</div>
    </div>
  );
}
export default Button;