import { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import HttpStatus from 'http-status-codes';
import Page from "components/Page";
import OrderStatusBadge from "components/OrderStatusBadge";
import TrackingDetail from 'components/TrackingDetail';
import DownloadInvoiceButton from 'components/DownloadInvoiceButton';
import ItemSummary from 'components/ItemSummary';
import CouponButton from 'components/CouponButton';
import Button from 'components/Button';
import Toast from 'components/Toast';
import ComplainForm from 'components/ComplainForm';
import CheckoutForm from 'components/CheckoutForm';
import ConfirmationComplete from 'components/ConfirmationComplete';
import OrderNotFound from './OrderNotFound';
import { getOrderDetail, completedOrder } from 'service/OrderService';
import { afterCheckout } from 'service/PaymentService';
import * as Lib from 'utils/Lib';
import Register from './Register';

import TransactionStatus from 'components/TransactionStatus';




function Pay() {

  const { orderUid } = useParams();

  const [orderNotFound, setOrderNotFound] = useState(false);
  const [claimed, setClaimed] = useState(false);

  const [orderId, setOrderId] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [transactionId, setTransactionId] = useState(null);
  const [couponId, setCouponId] = useState(null);
  const [refundProcessId, setRefundProcessId] = useState(null);

  const [merchantImage, setMerchantImage] = useState(null);
  const [merchantName, setMerchantName] = useState('');
  const [orderDate, setOrderDate] = useState('');
  
  const [orderStatus, setOrderStatus] = useState('AWAITING_PAYMENT');
  const [shippingStatus, setShippingStatus] = useState(null);

  const [customerName, setCustomerName] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [customerAddress, setCustomerAddress] = useState('');
  const [courier, setCourier] = useState('');
  const [waybill, setWaybill] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState(null);
  const [items, setItems] = useState([]);
  const [platformFee, setPlatformFee] = useState(0);
  const [shipppingFee, setShippingFee] = useState(0);
  const [shippingInsuranceFee, setShippingInsuranceFee] = useState(null);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [couponCode, setCouponCode] = useState(null);
  const [amount, setAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const [reference, setReference] = useState(null);

  // TODO: consider to add column amount, amount_with_discount, etc

  const [cancellationStatus, setCancellationStatus] = useState(null);


  const [trackingVisible, setTrackingVisible] = useState(false);
  const [complainFormVisible, setComplainFormVisible] = useState(false);
  const [paymentFormVisible, setPaymentFormVisible] = useState(false);
  const [confirmationCompleteVisible, setConfirmationCompleteVisible] = useState(false);

  const toast = useRef();
  const navigate = useNavigate();


  const fetchOrder = () => {

    getOrderDetail(orderUid,
      (status, body) => {

        if (status === HttpStatus.OK) {

          const order = body.data;

          setClaimed(order.customerId !== null);

          setCustomerId(order.customerId);
          setTransactionId(order.transactionId);
          setCouponId(order.couponId);
          setRefundProcessId(order.refundProcessId);

          setMerchantImage(Lib.imageURL(order.merchantImage));
          setMerchantName(order.merchantName);
          setOrderDate(order.orderDate);
          setOrderStatus(order.orderStatus);
          setShippingStatus(order.shippingStatus);
          setCustomerName(order.customerName);
          setCustomerPhone(order.customerPhone);
          setCustomerAddress(order.customerAddress);
          setCourier(order.courier);
          setWaybill(order.waybill);
          setInvoiceNumber(null);
          setItems(order.orderDetails);
          setShippingFee(order.shippingFee);
          setPlatformFee(order.platformFee);
          setShippingInsuranceFee(order.shippingInsuranceFee);
          setDiscountAmount(discountAmount);
          setAmount(order.amount);
          setTotalAmount(order.totalAmount);
          setCouponCode(order.couponCode);

          setReference(order.reference);

        }
        else if (status === HttpStatus.NOT_FOUND) {
          setOrderNotFound(true);
        }

      });

  }

  useEffect(() => {

    fetchOrder();

    

  }, []);


  const handleAfterCheckout = (newReference ,resultCode) => {

    if(resultCode === '00'){
      toast.current.show('Payment success');
      fetchOrder();
    }
    else if(resultCode === '01'){
      setReference(newReference);
      toast.current.show('Waiting your payment');
    }
    else if(resultCode === '02'){
      toast.current.show('Please complete your payment');
    }
    else if(resultCode === '03'){
      toast.current.show('Something wrong, please try again');
    }

    afterCheckout(orderUid, newReference, resultCode,
      (status, body) => {
        setPaymentFormVisible(false);
      });

  }

  const handleCompleteOrder = () => {

    completedOrder(orderUid, 
      (status, body) => {
        
        if(status === HttpStatus.OK){
          toast.current.show('Order completed. Thank you!');
          fetchOrder();
        }

        setConfirmationCompleteVisible(false)

      });

  }

  const handleComplainSubmitted = () => {
    setComplainFormVisible(false);
    toast.current.show('Complain submitted');
    fetchOrder();
  }

    




  const renderShippingCourier = () => (
    <div>
      <div className="flex flex-row mb-2">
        <div className="text-gray-400 w-24">Kurir:</div>
        <div className="font-bold">{courier}</div>
      </div>
      <div className="flex flex-row">
        <div className="text-gray-400 w-24">No Resi:</div>
        <div className="font-bold cursor-pointer"
          onClick={() => {
            navigator.clipboard.writeText(waybill);
            toast.current.show('Copied to clipboard');
          }}>
          {waybill}
          <span><i className="fi fi-br-copy text-sm ml-1"></i></span>
        </div>
        <span className="mx-2 text-gray-400">&bull;</span>
        <div className="font-bold text-primary cursor-pointer" role="button"
          onClick={() => setTrackingVisible(true)}>Lacak</div>
      </div>
    </div>
  );

  const renderShippingDiv = () => (
    <>
      <div className='border-t-4 border-dashed border-gray-200 my-10'></div>
      <div className="mx-6">
        <div className="text-gray-400 mb-5">Kirim ke:</div>
        <div className="font-bold mb-2">{customerName}</div>
        <div className="mb-2">{customerPhone}</div>
        <div className="flex flex-row mb-10">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-5 h-5 mr-2">
            <path d="M12,.042a9.992,9.992,0,0,0-9.981,9.98c0,2.57,1.99,6.592,5.915,11.954a5.034,5.034,0,0,0,8.132,0c3.925-5.362,5.915-9.384,5.915-11.954A9.992,9.992,0,0,0,12,.042ZM12,14a4,4,0,1,1,4-4A4,4,0,0,1,12,14Z" />
          </svg>
          <div>{customerAddress}</div>
        </div>

        {waybill && renderShippingCourier()}

      </div>
      <div className='border-t-4 border-dashed border-gray-200 my-10'></div>
    </>
  );

  const renderInvoiceButton = () => (
    invoiceNumber && <DownloadInvoiceButton invoiceNumber={invoiceNumber} />
  );

  const renderComplainButton = () => {
    if(orderStatus === 'COMPLAIN'){
      return(
        <div className="flex flex-row items-center py-5 cursor-pointer" role="button"
          onClick={() => navigate('complain', {replace: false})}>
          <i className="fi fi-br-shield-exclamation text-xl mr-1"></i>
          <div className="font-bold">Cek Komplain</div>
        </div> 
      );
    }
    else{
      return(
        <div className="flex flex-row items-center py-5 cursor-pointer" role="button"
          onClick={() => setComplainFormVisible(true)}>
          <i className="fi fi-br-shield-exclamation text-xl mr-1"></i>
          <div className="font-bold">Komplain</div>
        </div> 
      );
    }
  };


  const renderActionButton = () => {
    if (orderStatus === 'AWAITING_PAYMENT') {
      return (
        <Button label="PROSES PEMBAYARAN" className="bg-primary text-white h-12 w-full"
          onClick={() => setPaymentFormVisible(true)} />
      );
    }
    else if (orderStatus === 'PAID') {
      return (
        <div className="flex-col center">
          <div className="flex-row center mb-5">
            <i className="fi fi-br-hourglass-end text-xl mr-1"></i>
            <div className="font-bold">Menunggu proses pengiriman</div>
          </div>
        </div>
      );
    }
    else if (orderStatus === 'CANCELED'){
      return(
        <Button label="PENGEMBALIAN DANA" className="bg-primary text-white h-12 w-full" onClick={() => navigate('refund')}/>
      );
    }
    else if (orderStatus === 'SHIPPING'  || orderStatus == 'DELIVERED' || orderStatus === 'COMPLAIN') {
      return (
        <div className="flex-col center">
          <Button label="SELESAI" className="bg-primary text-white h-12 w-full" onClick={() => setConfirmationCompleteVisible(true)}/>
          {
            (orderStatus === 'DELIVERED' || orderStatus === 'COMPLAIN') && renderComplainButton()
          }
        </div>
      );
    }

  }





  if (orderNotFound) {
    return <OrderNotFound />
  }
  else {
    if (claimed) {
      return (
        <Page
          merchantImage={merchantImage}
          merchantName={merchantName}>

          <div>

            <Toast ref={toast} />

            <ComplainForm orderUid={orderUid} visible={complainFormVisible} 
              onClose={() => setComplainFormVisible(false)}
              onSubmit={handleComplainSubmitted} />

            <CheckoutForm 
              orderUid={orderUid} 
              amount={amount}
              shippingFee={shipppingFee}
              platformFee={platformFee}
              shippingInsuranceFee={shippingInsuranceFee}
              totalAmount={totalAmount}
              visible={paymentFormVisible}
              reference={reference}
              onClose={() => setPaymentFormVisible(false)}
              onAfterCheckout={(newReference, resultCode) => handleAfterCheckout(newReference, resultCode)} />

            <TrackingDetail visible={trackingVisible}
              orderUid={orderUid}
              courier={courier} 
              waybill={waybill} 
              onClose={() => setTrackingVisible(false)} />
            
            <ConfirmationComplete visible={confirmationCompleteVisible} 
              onClose={() => setConfirmationCompleteVisible(false)}
              onComplete={handleCompleteOrder}/>

            <div className="flex justify-center">
              <OrderStatusBadge status={orderStatus} />
            </div>

            <div className="mx-16 my-10">
              <TransactionStatus orderStatus={orderStatus} shippingStatus={shippingStatus}/>
            </div>

            {renderShippingDiv()}

            <div className="mx-6">
              <div className="flex flex-row justify-between mb-5">
                <div className="font-bold">{`#${orderUid}`}</div>
                <div>{Lib.dateTime(orderDate)}</div>
              </div>
              <div className="mb-5">
                {renderInvoiceButton()}
              </div>

              <div className="mb-5">
                <ItemSummary
                  couponCode={null}
                  items={items}
                  shippingFee={shipppingFee}
                  platformFee={platformFee}
                  shippingInsuranceFee={shippingInsuranceFee}
                  totalAmount={totalAmount} />
              </div>

              <div className="mb-20">
                <CouponButton />
              </div>

              {renderActionButton()}

            </div>
          </div>

        </Page>
      );
    }
    else {
      return <Register
        orderUid={orderUid}
        merchantImage={merchantImage}
        merchantName={merchantName}
        amount={amount} 
        onRegistered={() => {

          fetchOrder();

        }}/>
    }
  }

}
export default Pay;