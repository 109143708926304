import { useState, useRef, useEffect } from "react";
import HttpStatus from 'http-status-codes';
import BeatLoader from 'react-spinners/BeatLoader';
import IconButton from "./IconButton";
import { checkout } from 'service/PaymentService';
import * as Lib from 'utils/Lib';



function CheckoutForm({
  visible = false,
  orderUid = '',
  amount = 0,
  shippingFee = 0,
  platformFee = 0,
  shippingInsuranceFee = null,
  totalAmount = 0,
  reference = null,
  onAfterCheckout = (reference, resultCode) => {},
  onClose = () => { }
}) {

  const [checkoutLoading, setCheckoutLoading] = useState(false);



  useEffect(() => {

    if(visible){
      document.body.setAttribute('style', 'overflow: hidden;');   // disabled scroll while showing preview.
    }
    else{
      document.body.setAttribute('style', 'overflow: auto;');
    }

  }, [visible]);


  
  useEffect(() => {

    const script = document.createElement('script');
    script.src = "https://app-prod.duitku.com/lib/js/duitku.js";
    script.async = true;
    document.body.appendChild(script);

    return () => document.body.removeChild(script);

  }, []);

  const handleAfterCheckout = (inputReference) => {

    window.checkout.process(inputReference, {
        successEvent: function(result){

          console.log('payment success');
          console.log(result);

          onAfterCheckout(inputReference, '00');
        },
        pendingEvent: function(result){

          console.log('payment pending');
          console.log(result);

          onAfterCheckout(inputReference, '01');
        },
        errorEvent: function(result){

          console.log('payment error');
          console.log(result);

          onAfterCheckout(inputReference, '03');
        },
        closeEvent: function(result){

          console.log('closed popout without finishing the payment');
          console.log(result);

          onAfterCheckout(inputReference, '02');
        }

    });

  }

  const handleCheckout = () => {

    if(checkoutLoading){
      return;
    }

    if(reference ===  null){

      setCheckoutLoading(true);

      checkout(orderUid, 
        (status, body) => {

          setCheckoutLoading(false);
  
          if(status === HttpStatus.OK){
              
            const latestReference = body.data.reference;
            console.log(latestReference);

            handleAfterCheckout(latestReference);

          }
        });
    
    }
    else{
      handleAfterCheckout(reference);
    }

  }


  const handleClose = () => {
    onClose();
  }




  const renderSummary = () => (
    <>
      <div className="flex flex-row items-center justify-between p-6 border-b-2">
        <div className="font-bold text-xl">Checkout</div>
        <IconButton icon={<i className="fi fi-br-cross-small text-2xl"></i>}
          onClick={handleClose} />
      </div>
      <div className="px-6 py-8 mb-10">
        <div className="font-bold mb-10">{`#${orderUid}`}</div>

        <div className="flex flex-row justify-between mb-5">
          <div>Harga</div>
          <div className="font-bold">{Lib.formatCurrency(amount, true)}</div>
        </div>
        <div className="flex flex-row justify-between mb-5">
          <div>Ongkos Kirim</div>
          <div className="font-bold">{Lib.isFree(shippingFee)}</div>
        </div>
        <div className="flex flex-row justify-between mb-5">
          <div>Biaya Aplikasi</div>
          <div className="font-bold">{Lib.isFree(5000)}</div>
        </div>
        <div className="flex flex-row justify-between mb-5">
          <div>Biaya Payment Gateway</div>
          <div className="font-bold">{Lib.isFree(5000)}</div>
        </div>
        {
          shippingInsuranceFee !== null &&
          <div className="flex flex-row justify-between mb-5">
            <div>Asuransi pengiriman</div>
            <div className="font-bold">{Lib.isFree(shippingInsuranceFee)}</div>
          </div>
        }
      </div>

      <div className="flex flex-row justify-between font-bold p-6 border-t-2">
        <div>Total pembayaran</div>
        <div>{Lib.formatCurrency(totalAmount, true)}</div>
      </div>

      <div className="flex flex-row justify-between p-6 bg-primary text-white font-bold cursor-pointer"
        role="button" onClick={handleCheckout}>
        
        <div className="flex flex-row items-center">
          <i className="fi fi-br-shield-check text-2xl mr-2"></i>
          <div>PILIH PEMBAYARAN</div>
        </div>
        {
          checkoutLoading ? 
          <BeatLoader speedMultiplier={0.75} color="white" size={6}/> :
          <i className="fi fi-br-angle-small-right text-2xl"></i>
        }
      </div>
    </>
  );


  return (
    visible &&
    <div className="fixed flex flex-col items-center top-0 bottom-0 left-0 right-0 z-10 bg-black bg-opacity-75">

      <div className="bg-white w-full max-w-lg rounded-xl my-10 overflow-y-auto">
        {renderSummary()}
      </div>
    </div>
  );
}
export default CheckoutForm;