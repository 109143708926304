

function Message({
  messageType = 'INFO',   // INFO/WARN
  message = '',
}) {



  if(message !== null && message !== ''){
    return(
      <div className={`flex flex-row items-center p-4 rounded-xl ${messageType == 'WARN' ? 'bg-red-50' : 'bg-green-100'}`}>
        <i className={`fi fi-br-exclamation ${messageType == 'WARN' ? 'text-red-400' : 'text-green-400'} text-xl`}></i> 
        <div className="ml-2 text-sm font-bold">{message}</div>
      </div>
    );
  }

  return null;
}
export default Message;