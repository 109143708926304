import React, { useState, useEffect, useRef } from 'react';
import HttpStatus from 'http-status-codes';
import BeatLoader from 'react-spinners/BeatLoader';
import { getRegionList } from 'service/OrderService';
import useOnclickOutside from 'react-cool-onclickoutside';
import pinIcon from 'images/pin-icon.png';



function RegionField({
  region = null,
  className = '',
  onSelected = (region) => {}
}){


  const [keyword, setKeyword] = useState('');
  const [results, setResults] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [itemsVisible, setItemsVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const componentRef = useOnclickOutside(() => {
    handleHideItems();
  });

  

  useEffect(() => {

    if(region !== null){
      setSelectedRegion(region);
    }

  }, [region]);



  const handleHideItems = () => {

    setItemsVisible(false);
    setKeyword('');
    setResults([]);
    setLoading(false);

  }

  const handleSelectedItem = (i) => {

    const selectedRegion = results[i];
    setSelectedRegion(selectedRegion);
    onSelected(selectedRegion);


    handleHideItems();
  }

  const fetchRegionList = () => {

    setLoading(true);

    getRegionList(keyword, 
      (status, body) => {
        
        if(status === HttpStatus.OK){
          setResults(body.data)
        }
        else {
          setResults([]);
        }

        setLoading(false);

      });
  }

  useEffect(() => {

    if(keyword.length < 3){
      setResults([]);
    }
    else{
      fetchRegionList();
    }

  }, [keyword]);



  const renderSearchField = () => (
    <div className="flex flex-row items-center mb-5">
      <i className="fi fi-br-search text-secondary"></i>
      <input className="text-sm font-bold border-none outline-none focus:ring-0 bg-transparent w-full px-5"
          type="text" placeholder="Cari kecamatan" value={keyword} 
          onChange={e => setKeyword(e.target.value)}/>
      <div className="center w-8 h-8 rounded-full bg-gray-100 cursor-pointer" 
        onClick={handleHideItems}>
        <i className="fi fi-br-cross-small"></i>
      </div>
    </div>
  );


  const renderItems = () => (
    <div className="absolute top-14 left-0 right-0 border border-primary ring ring-primary ring-opacity-20  bg-white rounded-xl z-20 shadow-sm p-5">
      {renderSearchField()}
      {
        loading ?
        <div>
          <BeatLoader loading={loading} speedMultiplier={0.75} color="#0E1823" size={4}/> 
        </div> :
        <div>
          {
            results.length === 0 ?
            <div className="text-gray-300 text-sm italic">Hasil pencarian tidak ada</div> :
            <div className="max-h-40 overflow-y-auto">
            {
              results.map((item, i) => (
                <div key={i} className="text-gray-400 cursor-pointer text-sm hover:text-secondary py-3"
                  onClick={() => handleSelectedItem(i)}>{item.regionDetail}</div>
              ))
            }
            </div>
          }
        </div>
      }
    </div>
  );

  const renderSelectedRegion = () => {
    if(selectedRegion !== null){
      return (
        <div className="flex flex-row flex-1">
          <img className="w-4 h-4 mr-1" src={pinIcon} alt="pin icon"/>
          <div className=" text-sm font-bold">{selectedRegion.regionDetail}</div>
        </div>
      )
    }
    else{
      return <div className="flex-1 text-sm font-bold text-gray-300"></div>
    }
  }



  return(
    <div ref={componentRef}>
      <div className="text-gray-400 text-sm mb-1">Region(kecamatan)</div>
      <div className="relative">
        <div className={`flex flex-row items-center w-full h-12 px-5 rounded-xl  shadow-sm border border-gray-200
          focus-within:border-primary focus-within:ring focus-within:ring-primary focus-within:ring-opacity-20 
          box-border placeholder-gray-400 cursor-pointer ${className}`}
          onClick={() => {
            if(itemsVisible){
              handleHideItems();
            }
            else{
              setItemsVisible(true);
            }
          }}>

          {renderSelectedRegion()}
          <i className="fi fi-br-angle-small-down text-secondary"></i>

        </div>
        {itemsVisible && renderItems()}
      </div>
    </div>
  )





}
export default RegionField;
