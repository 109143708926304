import { useState, useRef, useEffect } from "react";
import HttpStatus from 'http-status-codes';
import BeatLoader from 'react-spinners/BeatLoader';
import { getAvailableTariff } from 'service/OrderService';
import * as Lib from 'utils/Lib';
import useOnclickOutside from 'react-cool-onclickoutside';



function TarffOption({
  orderUid = null,
  destination = null,
  coordinate = null,
  className = '',
  onSelected = (duration, courier, insuranceFee) => {},
}) {

  const [label, setLabel] = useState('');
  const [enabled, setEnabled] = useState(false);
  const [durationList, setDurationList] = useState([]);
  const [tariffList, setTariffList] = useState([]);
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [selectedTariff, setSelectedTariff] = useState(null);

  const [itemVisible, setItemVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const availableTariffList = useRef([]);

  const componentRef = useOnclickOutside(() => {
    setItemVisible(false);
    setSelectedDuration(null);
    setSelectedTariff(null);
  });


  const reset = () => {
    setDurationList([]);
    setTariffList([]);
    setSelectedDuration(null);
    setSelectedTariff(null);

    setItemVisible(false);
    setLoading(false);
  }




  const setAvailableDuration = () => {

    let regularAvailable = false;
    let nextDayAvailable = false;
    let sameDayAvailable = false;
    let instantAvailable = false;
    let regularRates = [];
    let nextDayRates = [];
    let sameDayRates = [];
    let instantRates = [];

    setDurationList([]);


    for (let i = 0; i < availableTariffList.current.length; i++) {

      const tariff = availableTariffList.current[i];

      if (tariff.shippingDuration === 'REGULAR') {
        regularRates.push(tariff.rate);
        regularAvailable = true;
      }
      else if (tariff.shippingDuration === 'NEXT_DAY') {
        nextDayRates.push(tariff.rate);
        nextDayAvailable = true;
      }
      else if (tariff.shippingDuration === 'SAME_DAY'){
        sameDayRates.push(tariff.rate);
        sameDayAvailable = true;
      }
      else if (tariff.shippingDuration === 'INSTANT') {
        instantRates.push(tariff.rate);
        instantAvailable = true;
      }

    }

    if (regularAvailable) {

      const durationItem = {
        'label': 'Regular',
        'value': 'REGULAR'
      }

      regularRates.sort();

      if (regularRates.length > 1) {
        durationItem.rate = `${Lib.formatCurrency(regularRates[0], true)} - ${Lib.formatCurrency(regularRates[regularRates.length - 1], true)}`;
      }
      else {
        durationItem.rate = Lib.formatCurrency(regularRates[0], true);
      }

      setDurationList(items => [...items, durationItem]);
    }
    if (nextDayAvailable) {

      const durationItem = {
        'label': 'Next Day',
        'value': 'NEXT_DAY'
      }

      nextDayRates.sort()

      if (nextDayRates.length > 1) {
        durationItem.rate = `${Lib.formatCurrency(nextDayRates[0], true)} - ${Lib.formatCurrency(nextDayRates[nextDayRates.length - 1], true)}`;
      }
      else {
        durationItem.rate = Lib.formatCurrency(nextDayRates[0], true);
      }

      setDurationList(items => [...items, durationItem]);
    }
    if (sameDayAvailable) {

      const durationItem = {
        'label': 'Same Day',
        'value': 'SAME_DAY'
      }

      sameDayRates.sort();

      if (sameDayRates.length > 1){
        durationItem.rate = `${Lib.formatCurrency(sameDayRates[0], true)} - ${Lib.formatCurrency(sameDayRates[sameDayRates.length - 1], true)}`;
      }
      else {
        durationItem.rate = Lib.formatCurrency(sameDayRates[0], true);
      }

      setDurationList(items => [...items, durationItem]);

    }
    if (instantAvailable) {

      const durationItem = {
        'label': 'Instant',
        'value': 'INSTANT'
      }

      instantRates.sort();

      if (instantRates.length > 1) {
        durationItem.rate = `${Lib.formatCurrency(instantRates[0], true)} - ${Lib.formatCurrency(instantRates[instantRates.length - 1], true)}`;
      }
      else {
        durationItem.rate = Lib.formatCurrency(instantRates[0], true);
      }

      setDurationList(items => [...items, durationItem]);
    }

  }

  const setAvailableTariff = (duration) => {

    const tList = [];


    for (let i = 0; i < availableTariffList.current.length; i++) {

      const tariff = availableTariffList.current[i];

      if (tariff.shippingDuration === duration.value) {

        let courierName = tariff.courier;
        if (tariff.courier === 'SICEPAT') {
          courierName = 'SiCepat';
        }
        else if (tariff.courier === 'ANTERAJA') {
          courierName = 'AnterAja'
        }
        else if (tariff.courier === 'GRAB_EXPRESS') {
          courierName = 'Grab Express'
        }
        

        tList.push({
          'courier': tariff.courier,
          'courierName': courierName,
          'rate': tariff.rate,
          'description': tariff.description,
          'insuranceFee': tariff.insuranceFee
        });
      }


    }

    setTariffList(tList);


  }



  useEffect(() => {

    setEnabled(destination !== null);

    reset();

    if (destination !== null) {

      setLoading(true);

      getAvailableTariff(orderUid, destination, coordinate,
        (status, body) => {

          setLoading(false)

          if (status === HttpStatus.OK) {

            availableTariffList.current = body.data;

            setAvailableDuration();

          }

        });

    }

  }, [destination, coordinate]);



  const handleSelectedDuration = (duration) => {
    setSelectedDuration(duration);
    setAvailableTariff(duration);
  }

  const handleSelectedTariff = (tariff) => {
    setSelectedTariff(tariff);
    setLabel(`${tariff.courierName} (${Lib.formatCurrency(tariff.rate, true)})`);
    setItemVisible(false);

    console.log('tariffff');
    console.log(tariff);

    onSelected(selectedDuration.value, tariff.courier, tariff.insuranceFee);
  }





  const renderLabel = () => (
    <div className={`flex flex-row items-center w-full text-sm font-bold ${enabled ? 'text-secondary' : 'text-gray-400'}`}>
      {
        (selectedTariff === null) ?
          <div className="w-full">
            Pengiriman
          </div> :
          <div className="w-full">
            {label}
          </div>
      }
      <i className={`fi fi-br-angle-small-down ${enabled ? 'text-secondary' : 'text-gray-400'}`}></i>
    </div>
  );

  const renderItems = () => (
    <div className="absolute top-14 left-0 right-0 border border-primary ring ring-primary ring-opacity-20  
      bg-white rounded-xl z-20 shadow-sm py-5">
      {
        selectedDuration === null ?
          <div>
            {
              durationList.length === 0 ?
                <div className="text-gray-300 text-sm italic">Tarif tidak ditemukan</div> :
                <div className="max-h-40 overflow-y-scroll">
                  {
                    durationList.map((item, i) => (
                      <div key={i} className="text-gray-400 cursor-pointer text-sm px-5 py-4 hover:bg-gray-50"
                        onClick={() => handleSelectedDuration(item)}>
                        <div className="flex flex-row justify-between">
                          <div className="font-bold text-secondary">{item.label}</div>
                          <div>{item.rate}</div>
                        </div>
                      </div>
                    ))
                  }
                </div>
            }
          </div> :
          <div>
            <div className="flex flex-row items-center mb-5">
              <div className="center pl-5 pr-2 cursor-pointer"
                onClick={() => setSelectedDuration(null)}>
                <i className="fi fi-br-angle-small-left"></i>
              </div>
              <div className="font-bold">{selectedDuration.label}</div>
            </div>
            <div className="max-h-40 overflow-y-scroll">
              {
                tariffList.map((item, i) => (
                  <div key={i} className="text-gray-400 cursor-pointer text-sm px-5 py-4 hover:bg-gray-50"
                    onClick={() => handleSelectedTariff(item)}>
                    <div className="flex flex-row justify-between">
                      <div className="font-bold text-secondary">{item.courierName}</div>
                      <div>{Lib.formatCurrency(item.rate, true)}</div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
      }
    </div>
  );




  return (
    <div ref={componentRef}>
      <div className="relative">
        <div className={`flex flex-row items-center w-full h-12 px-5 rounded-xl shadow-sm border 
          ring ${enabled ? 'border-primary ring-primary' : 'border-gray-200 ring-gray-400'} 
          ring-opacity-20 box-border cursor-pointer ${className}`}
          onClick={() => {

            if (!enabled) {
              return;
            }
            if (loading) {
              return;
            }

            if (itemVisible) {
              setItemVisible(false);
            }
            else {
              setItemVisible(true);
            }
          }}>

          {
            loading ?
              <div className="text-sm font-bold">Memuat tarif..</div> :
              renderLabel()
          }
        </div>
        {itemVisible && renderItems()}
      </div>
    </div>
  )

}
export default TarffOption;