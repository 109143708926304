import {useState} from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';

function ListBox({
  label,
  placeholder = 'Search',
  items,
  className,
  selectedIndex = -1,
  onSelected = (i, item) => {}
}){

  const [itemVisible, setItemVisible] = useState(false);
  const componentRef = useOnclickOutside(() => {
    setItemVisible(false);
  });



  const handleSelectedItem = (i) => {
    onSelected(i, items[i]);

    setItemVisible(false);

    
  }



  const renderItems = () => (
    <div className="absolute top-14 left-0 right-0 
      bg-white border border-gray-300 rounded-xl z-20 shadow-sm p-5">
      <div className="max-h-40 overflow-y-auto">
        {
          items.map((item, i) => (
            <div key={i} className="text-gray-400 cursor-pointer text-sm hover:text-secondary py-2"
              onClick={() => handleSelectedItem(i)}>{item.label}</div>
          ))
        }
      </div>
    </div>
  );

  const renderSelectedItem = () => {
    if(selectedIndex >= 0){
      return <div className="flex-1 text-sm font-bold">{items[selectedIndex].label}</div>
    }
    else{
      return <div className="flex-1 text-sm font-bold text-gray-300">{placeholder}</div>
    }
  }

  return(
    <div ref={componentRef}>
      <label className="text-gray-400 text-sm">{label}</label>
      <div className="relative">
        <div className={`flex flex-row items-center w-full h-12 mt-1 px-5 rounded-xl  shadow-sm border border-gray-200
          focus-within:border-primary focus-within:ring focus-within:ring-primary focus-within:ring-opacity-20 
          box-border placeholder-gray-400 cursor-pointer ${className}`}
          onClick={() => {
            setItemVisible(!itemVisible);

          }}>

          {renderSelectedItem()}
          <i className="fi fi-br-angle-small-down text-secondary"></i>

        </div>
        {itemVisible && renderItems()}
      </div>
    </div>
  );

}
export default ListBox;