import React, { useState, useEffect, useRef, useMemo } from 'react';
import {usePopper} from 'react-popper';
import useOnclickOutside from 'react-cool-onclickoutside';


function SearchableListBox({
  label,
  placeholder = 'Search',
  items = [],
  className,
  selectedIndex = -1,
  searchable = true,
  onSelected = (i, item) => {}
}) {

  const [searchItems, setSearchItems] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [itemsVisible, setItemsVisible] = useState(false);
  
  const componentRef = useOnclickOutside(() => setItemsVisible(false));

  const indexedItems = useMemo(() => {

    // to keep 'index' when doing filtered search
    const indexedItems = [];
    for (let i = 0; i < items.length; i++) {
      let item = items[i];
      item.index = i;
      indexedItems.push(item);
    }

    return indexedItems;
  }, [items]);


  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(
    referenceElement, 
    popperElement, 
    {
      placement: 'bottom-start',
      modifiers: [
        {
          name: "offset",
          enabled: true,
          options: {
            offset: [0, 10]
          }
        },
        { name: 'arrow', options: { element: arrowElement } }
      ],
    }
  );


  useEffect(() => {
    setSearchItems(indexedItems);
  }, [items]);


  const handleSelectedItem = (i) => {
    onSelected(searchItems[i].index, searchItems[i]);

    handleHideItems();

    
  }

  const handleHideItems = () => {

    setItemsVisible(false);
    setKeyword('');
    setSearchItems(indexedItems);

  }

  const handleSearchItem = (keyword) => {

    setKeyword(keyword);

    if(keyword.length >= 3){

      const filteredItems = [];

      for (let i = 0; i < indexedItems.length; i++) {
        let item = indexedItems[i];

        if(item.label.toLowerCase().includes(keyword.toLowerCase())){
          filteredItems.push(item);
        }

      }

      setSearchItems(filteredItems);
    }
    else{
      setSearchItems(indexedItems);
    }

  }

  const renderSearchField = () => (
    <div className="flex flex-row items-center mb-5">
      <i className="fi fi-br-search text-secondary"></i>
      <input className="text-sm font-bold border-none outline-none focus:ring-0 bg-transparent w-full pl-5"
          type="text" placeholder="Search" value={keyword} 
          onChange={e => handleSearchItem(e.target.value)}/>
    </div>
  );




  const renderSelectedItem = () => {
    if(selectedIndex >= 0){
      return <div className="flex-1 text-sm font-bold">{items[selectedIndex].label}</div>
    }
    else{
      return <div className="flex-1 text-sm font-bold text-gray-300">{placeholder}</div>
    }
  }

  const renderItems = () => (
    <div ref={setPopperElement} style={styles.popper} {...attributes.popper} className="w-80 z-50" >
      <div ref={setArrowElement} style={styles.arrow} />
      <div className="bg-white border border-gray-300 rounded-xl shadow-sm p-5 ">
        {searchable && renderSearchField()}
        <div className="max-h-40 overflow-y-auto">
          {
            searchItems.map((item, i) => (
              <div key={i} className="text-gray-400 cursor-pointer text-sm hover:text-secondary py-2"
                onClick={() => handleSelectedItem(i)}>{item.label}</div>
            ))
          }
        </div>
      </div>
      
    </div>
  );


  return(
    <div ref={componentRef}>
      {label && <div className="text-gray-400 text-sm mb-1">{label}</div>}
      <>
        <div ref={setReferenceElement} className={`flex flex-row items-center w-full h-12 px-5 rounded-xl  shadow-sm border border-gray-200
          focus-within:border-primary focus-within:ring focus-within:ring-primary focus-within:ring-opacity-20 
          box-border placeholder-gray-400 cursor-pointer ${className}`}
          onClick={() => {
            if(itemsVisible){
              setItemsVisible(false);
              handleHideItems();
            }
            else{
              setItemsVisible(true);
            }
          }}>

          {renderSelectedItem()}
          <i className="fi fi-br-angle-small-down text-secondary"></i>
        </div>
        {itemsVisible && renderItems()}
      </>
    </div>
  );
}
export default SearchableListBox;