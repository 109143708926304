

function OrderStatusBadge({ status }) {

  const renderBadge = (label, color) => (
    <div className={`text-xs font-bold px-4 py-1 rounded-lg inline-block ${color}`}>{label}</div>
  );


  if(status === 'AWAITING_PAYMENT'){
    return renderBadge('menunggu pembayaran', 'bg-purple-200');
  }
  else if(status === 'PAID'){
    return renderBadge('lunas', 'bg-yellow-200');
  }
  else if (status === 'SHIPPING') {
    return renderBadge('proses pengiriman', 'bg-blue-200');
  }
  else if (status === 'DELIVERED') {
    return renderBadge('terkirim', 'bg-blue-300');
  }
  else if (status === 'COMPLETED') {
    return renderBadge('selesai', 'bg-green-200');
  }
  else if (status === 'CANCELED') {
    return renderBadge('dibatalkan', 'bg-gray-400');
  }
  else if (status === 'EXPIRED') {
    return renderBadge('expired', 'bg-gray-300');
  }
  else if (status === 'COMPLAIN') {
    return renderBadge('komplain', 'bg-red-200');
  }
  else if (status === 'REFUNDED') {
    return renderBadge('dana dikembalikan', 'bg-yellow-400');
  }
  else{
    return (<div></div>)
  }

}
export default OrderStatusBadge;