import {useState, useRef} from 'react';
import HttpStatus from 'http-status-codes';
import BeatLoader from 'react-spinners/BeatLoader';
import Message from 'components/Message';
import Page from "components/Page";
import TextField from 'components/TextField';
import PhoneField from 'components/PhoneField';
import RegionField from 'components/RegionField';
import TariffOption from 'components/TariffOption';
import PhoneVerificationForm from 'components/PhoneVerificationForm';
import CheckBox from 'components/CheckBox';
import * as Lib from 'utils/Lib';
import { claimOrder } from 'service/OrderService';
import { requestOTP } from 'service/AuthService';
import noLocation from 'images/icon-no-location.png';
import location from 'images/icon-location.png';
import MapsForm from 'components/MapsForm';



function Register({
  orderUid = '',
  orderId,
  merchantImage = null,
  merchantName = '',
  amount = 0,
  onRegistered = () => {}
}) {


  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [region, setRegion] = useState(null);
  const [latLng, setLatLng] = useState(null);
  const [addressDetail, setAddressDetail] = useState('');


  const [message, setMessage] = useState('');

  const [shippingDuration, setShippingDuration] = useState(null);
  const [selectedCourier, setSelectedCourier] = useState(null); 
  const [insuranceFee, setInsuranceFee] = useState(null);
  const [useShippingInsurance, setUseShippingInsurance] = useState(true);

  const [phoneVerificationVisible, setPhoneVerificationVisible] = useState(false);
  const [showMapsForm, setShowMapsForm] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);



  const validateInput = () => {

    setMessage('');

    if(name === ''){
      setMessage('Nama wajib diisi');
      return false;
    }
    else if(email === ''){
      setMessage('Email wajib diisi');
      return false;
    }
    else if(phone === ''){
      setMessage('No telpon wajib diisi');
      return false;
    }
    else if(addressDetail === ''){
      setMessage('Mohon input alamat lengkap');
      return false;
    }
    else if(region === null){
      setMessage('Mohon input kecamatan anda');
      return false;
    }
    else if(selectedCourier === null){
      setMessage('Mohon pilih kurir');
      return false;
    }

    return true;
  }


  const handleRegister = () => {

    let coordinate = null;

    if(latLng !== null){
      coordinate = {
        latitude: latLng.lat,
        longitude: latLng.lng
      }
    }

    claimOrder(
      orderUid, 
      name, 
      email,
      phone, 
      region.regionId,
      addressDetail,
      shippingDuration,
      selectedCourier,
      coordinate,
      useShippingInsurance,
      (status, body) => {

        if(status === HttpStatus.OK){
          onRegistered();
        }
        else if(status === HttpStatus.BAD_REQUEST){
          setMessage('please fill all fields');
        }

      });

  }

  const handleRequestOTP = () => {

    setVerifyLoading(true);

    requestOTP(phone, 
      (status, body) => {
        if(status === HttpStatus.OK){
          setVerifyLoading(false);
          setPhoneVerificationVisible(true);
        }
      });
  }


  const renderAmount = () => (
    <div className="pattern py-4 rounded-xl flex center mb-4">
      <div>
        <div className="text-4xl font-bold">{Lib.formatCurrency(amount, true)}</div>
      </div>
    </div>
  );

  const renderVerifyButton = () => (
    <div className="flex-row center font-bold cursor-pointer rounded-xl shadow-sm hover:bg-opacity-90 
      bg-secondary text-white w-full h-12" role="button"
      onClick={() => {
        if(validateInput() && !verifyLoading){
          handleRequestOTP();
        }
      }}>
      <i className="fi fi-br-shield-check text-white text-xl mr-2"></i>
      {
        verifyLoading ?
        <BeatLoader speedMultiplier={0.75} color="white" size={6}/> :
        <div>Verifikasi</div>
      }
    </div>
  );

  const handleEditPinpoint = () => {
    setShowMapsForm(true);
  }


  const renderPinpoint = () => (
    <div>
      <div className="text-gray-400 text-sm">Atur pinpoint</div>
      <div className="cursor-pointer" onClick={handleEditPinpoint}>
        {
          latLng === null ?
            <div className="flex flex-row items-center w-full h-12 mt-1 px-5 border border-gray-200 rounded-xl">
              <div className="flex flex-row items-center w-full">
                <img className="w-5 mr-2" src={noLocation} alt="no location icon" />
                <div className="text-gray-300 text-sm font-bold">Atur pinpoint</div>
              </div>
              <i className="fi fi-br-angle-small-right text-secondary"></i>
            </div> :
            <div className="flex flex-row items-center w-full h-12 mt-1 px-5 border border-gray-200 rounded-xl">
              <div className="flex flex-row items-center w-full">
                <img className="w-5 mr-2" src={location} alt="location icon" />
                <div className="text-sm font-bold">Sudah pinpoint</div>
              </div>
              <div className="text-primary cursor-pointer text-sm" role="button">Ubah</div>
            </div>
        }
      </div>
    </div>
  );




  return(
    <Page 
      merchantImage={merchantImage}
      merchantName={merchantName}>

      <div className="mx-6">

        <PhoneVerificationForm visible={phoneVerificationVisible} 
          orderUid={orderUid}
          phone={phone}
          onClose={() => setPhoneVerificationVisible(false)}
          onVerified={(code) => {
            handleRegister();
          }}/>
        
        <MapsForm 
          givenPosition={latLng}
          visible={showMapsForm} 
          onSave={
            (pinpoint) => {
              setLatLng(pinpoint);
              setShowMapsForm(false);
            }}
          onClose={() => setShowMapsForm(false)}/>
        
        
        {renderAmount()}
        <div className="text-center font-bold mb-10">{`order id: #${orderUid}`}</div>

        <div className="mb-5">
          <Message message={message} messageType="WARN"/>
        </div>

        <div className="mb-10">
          <div className="font-bold mb-2">Informasi Pembeli</div>
          <TextField className="mb-2" label="Nama" onChange={(v) => setName(v)}/>
          <TextField className="mb-2" label="Email" onChange={(v) => setEmail(v)}/>
          <PhoneField className="mb-2" label="No. Telpon (kode dikirim via Whatsapp)" value={phone} onChange={(v) => setPhone(v)}/>
        </div>
        
        <div className="mb-10">
          <div className="font-bold mb-2">Pengiriman</div>
          <div className="mb-2">
            <RegionField region={region} onSelected={(region) => {
              setRegion(region);
            }} />
          </div>
          <TextField label="Alamat lengkap" className="mb-2" maxLength={200}
            value={addressDetail} onChange={(val) => setAddressDetail(val)}
            placeholder="Nama jalan, no rumah, kecamatan, kode pos, dll" />
          {
            renderPinpoint()
          }

        </div>

        
        <div className="mb-16">
        <div className="font-bold mb-2">Kurir</div>
          <TariffOption className="mb-5"
            orderUid={orderUid} 
            destination={region !== null ? region.regionId : null}
            coordinate={latLng}
            onSelected={(duration, courier, iFee) => {
              setShippingDuration(duration);
              setSelectedCourier(courier);
              setInsuranceFee(iFee);
            }}/>
          
          {
            (selectedCourier !== null && insuranceFee !== null) &&
            <div className="text-sm">
              <CheckBox label={<div>Asuransi pengiriman <span className="font-bold">{`(${Lib.formatCurrency(insuranceFee, true)})`}</span></div>} 
                checked={useShippingInsurance} 
                onChange={(val) => setUseShippingInsurance(val)}/>
          </div>
          }


        </div>
        
        
        <p className="text-sm text-gray-400 mb-2">
          Untuk keamanan transaksi anda, verifikasi nomor telpon dibutuhkan
        </p>
        
        {renderVerifyButton()}

      </div>
    </Page>
  );
}
export default Register;