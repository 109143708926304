import { useNavigate } from 'react-router-dom';



function SubPage({title, children}) {

  const navigate = useNavigate();


  return(
    <div className="w-full overflow-y-auto">
      <div className="bg-white w-full min-h-screen overflow-y-hidden max-w-xl mx-auto mt-10 
          pb-5 rounded-t-4xl">
          <div className="flex flex-row items-center py-10">
            <div className="pl-6 pr-2 cursor-pointer center" role="button"
              onClick={() => navigate(-1)}><i className="fi fi-br-angle-small-left text-2xl"></i></div>
            <div className="text-xl font-bold flex-1">{title}</div>
            <div className="px-6 cursor-pointer center"><i className="fi fi-br-info text-2xl"></i></div>
          </div>

          <div className="w-full px-6">
            {children}
          </div>
          
        </div>
    </div>
  );
}
export default SubPage;