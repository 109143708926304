import { useState, useRef, useEffect } from "react";
import HttpStatus from 'http-status-codes';
import ReactCodeInput from "react-code-input";
import LoadingButton from "./LoadingButton";
import IconButton from "./IconButton";
import { verifyOTP, requestOTP } from 'service/AuthService';


function PhoneVerificationForm({
  visible = false,
  orderUid= null,
  phone = null,
  onVerified = (code) => {},
  onClose = () => {}
}) {

  const [message, setMessage] = useState('');
  const [code, setCode] = useState('');
  const [countdown, setCountdown] = useState(0);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  
  const ref = useRef(null);

  
  const startCountdown = () => {
    ref.current = setInterval(() => {

      setCountdown((count) => count -1);

      return () => clearInterval(ref.current);
    }, 1000);
  }

  const stopCountdown = () => {
    clearInterval(ref.current);
  }

  const handleClose = () => {
    setResendLoading(false);
    setSubmitLoading(false);
    stopCountdown();
    onClose();
  }

  const handleSubmit = () => {

    setSubmitLoading(true);

    verifyOTP(orderUid, phone, code, 
      (status, body) => {

        setSubmitLoading(false);

        if(status === HttpStatus.OK){
          onVerified(code);
          stopCountdown();
        }
        else{
          setMessage('Invalid token');
        }
      });

  }

  const handleResend = () => {

    setResendLoading(true);

    requestOTP(phone, 
      (status, body) => {
        if(status === HttpStatus.OK){
          setResendLoading(false);
          setCountdown(59);
          startCountdown();
        }
      });
  }





  useEffect(() => {
    if(countdown === 0){
      stopCountdown();
    }

  }, [countdown]);


  useEffect(() => {

    // TODO: please check warning on console.log 
    if(visible){
      setCountdown(59);
      startCountdown();
    }
    else{
      stopCountdown();
    }
  }, [visible]);





  const renderResendButton = () => {
    if(countdown > 0){
      return(
        <div className="text-gray-400 text-sm">
          Tunggu &nbsp;<span className="font-bold">{`${countdown} detik`}</span>&nbsp; untuk kirim ulang.
        </div>
      );
    }
    else if(resendLoading){
      return(<div className="text-gray-400 text-sm">Sending OTP...</div>)
    }

    return(
      <div className="flex flex-row text-sm text-gray-400">
        Didn't get the code?&nbsp;<div className="text-primary font-bold cursor-pointer" role="button"
        onClick={handleResend}>Kirim ulang</div>
      </div>
    );
  }


  const inputProps = {
    inputStyle: {
      margin: '0 10px 0 0',
      MozAppearance: 'textfield',
      fontSize: '18px',
      fontWeight: '700',
      width: '45px',
      height: '50px',
      paddingLeft: '15px',
      backgroundColor: 'white',
      color: '#0E1823',
      border: '2px solid #0E1823',
      borderRadius: '10px',
    },
    inputStyleInvalid: {
      margin: '0 10px 0 0',
      MozAppearance: 'textfield',
      fontSize: '18px',
      fontWeight: '700',
      width: '45px',
      height: '50px',
      paddingLeft: '15px',
      backgroundColor: 'white',
      color: '#0E1823',
      border: '2px solid #0E1823',
      borderRadius: '10px',
    }
  }

  return(
    visible &&
    <div className="fixed flex flex-col items-center top-0 bottom-0 left-0 right-0 z-10 bg-black bg-opacity-75">
      
      <div className="bg-white w-full max-w-lg px-6 py-8 rounded-xl mt-60" >

        <div className="flex flex-row items-center justify-between mb-2">
          <i className="fi fi-br-shield-check text-4xl text-secondary"></i>
          <IconButton icon={<i className="fi fi-br-cross-small text-secondary text-2xl"></i>} onClick={handleClose} />
        </div>
        
        <div className="font-bold text-lg">Kami telah mengirimkan kode ke nomor (Whatsapp) kamu</div>

        <div className="font-bold text-red-400 my-5">{message}</div>

        <div className="my-10">
          <ReactCodeInput type="number" fields={4} {...inputProps}
            value={code} onChange={(v) => {setCode(v)}}/>
        </div>
        
        <div className="flex flex-row items-center">
          <LoadingButton className="bg-primary text-white h-12 w-32 mr-4" 
            label="Submit" 
            loading={submitLoading}
            loadingLabel=""
            onClick={() => {
              handleSubmit();
            }}/>
          {renderResendButton()}
        </div>
          
      </div>

    </div>
  );
  
}
export default PhoneVerificationForm;